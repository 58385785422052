import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { EnvironmentService } from 'src/app/environment.service';
import { LoginResponse } from '../login/login';

@Injectable({
  providedIn: 'root'
})
export class RegisterService {

    httpOptions: any;
    loginUrl;
    err = new LoginResponse();
    apiUrl;
    timeZone: any;
    constructor(private http: HttpClient, private envService: EnvironmentService) {
        this.setHttpHeaders();
        this.apiUrl = this.envService.read('apiUrl');
        this.timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    }

    setHttpHeaders() {
        this.httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'access-token': JSON.parse(localStorage.getItem('access-token'))
            })
        };
    }

    private handleError(err: HttpErrorResponse): Observable<any> {
        // handle your auth error or rethrow
        if (err && err.status === 401) {
            return of(err.error);
        }
        throw err.error;
    }

    getExistingAccounts(args): Observable<any> {
        args.timeZone = this.timeZone;
        return this.http.post<any>(`${this.apiUrl}/users/getExistinAccounts`, args,
            this.httpOptions).pipe(
                tap(),
                catchError((error) => {
                    this.handleError(error);
                    return of(error);
                })
            );
    }

    login(args): Observable<any> {
        args.timeZone = this.timeZone;
        this.err = { success: false, message: 'Something went wrong', user_data: null };
        this.httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': `Basic ${btoa(args.username + ':' + args.password)}`
            })
        };
        return this.http.post<any>(`${this.apiUrl}/dashboard/user/login`, args, this.httpOptions).pipe(
            tap(),
            catchError((error) => {
                this.handleError(error);
                return of(error);
            })
        );
    }

    addNewProject(args): Observable<any> {
        args.timeZone = this.timeZone;
        this.httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            })
        };
        return this.http.post<any>(`${this.apiUrl}/v1/addNewProject`, args, this.httpOptions).pipe(
            tap(),
            catchError((error) => {
                this.handleError(error);
                return of(error);
            })
        );
    }

    addMenuTabs(args): Observable<any> {
        args.timeZone = this.timeZone;
        this.httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            })
        };
        return this.http.post<any>(`${this.apiUrl}/dashboard/menutabs/add`, args, this.httpOptions).pipe(
            tap(),
            catchError((error) => {
                this.handleError(error);
                return of(error);
            })
        );
    }

    signupUser(args): Observable<any> {
        args.timeZone = this.timeZone;
        this.httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                Authorization: this.envService.read('Authorization'),
            })
        };
        return this.http.post<any>(`${this.apiUrl}/dashboard/user/signup`, args, this.httpOptions).pipe(
            tap(),
            catchError((error) => {
                this.handleError(error);
                return of(error);
            })
        );
    }

    proceedToSignUp(args): Observable<any> {
        args.timeZone = this.timeZone;
        this.httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            })
        };
        return this.http.post<any>(`${this.apiUrl}/v1/save/abc/client/landing/page`, args, this.httpOptions).pipe(
            tap(),
            catchError((error) => {
                this.handleError(error);
                return of(error);
            })
        );
    }
    addProjectUE(args): Observable<any> {
        args.timeZone = this.timeZone;
        this.httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            })
        };
        return this.http.post<any>(`${this.apiUrl}/add/project/ue`, args, this.httpOptions).pipe(
            tap(),
            catchError((error) => {
                this.handleError(error);
                return of(error);
            })
        );
    }
}
