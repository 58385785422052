import { AbstractControl } from '@angular/forms';

export class ValidatePassword {
  static MatchPassword(abstractControl: AbstractControl) {
    const password = abstractControl.get('password').value;
    const confirmPassword = abstractControl.get('confirmpassword').value;
    if (password !== confirmPassword) {
         abstractControl.get('confirmpassword').setErrors({
           MatchPassword: true
         });
    } else {
      return null;
    }
  }
}
