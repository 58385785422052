<div class="parent">
    <div class="inner">
        <div>
            <h1 i18n="@@oops">Oooops...</h1>
        </div>
        <div class="message" i18n="@@toastr_sorryMsg">
            Sorry, Something went wrong.
        </div>
        <div>
            <button class="btn btn-blue" (click)="checkApi()" i18n="@@checkApi">
                Check API
            </button>
        </div>
    </div>
</div>
<span i18n="@@toastr_sorryMsg"  name="sorryMsg" style="display: none;">Sorry, Something went wrong.</span>