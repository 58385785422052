import { enableProdMode , TRANSLATIONS_FORMAT , TRANSLATIONS } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

// platformBrowserDynamic().bootstrapModule(AppModule)


export function localeFactory(): string {
  return  navigator.language || window.navigator.language;
}


const local_variable = localeFactory();
if (local_variable !== 'id') {
  localStorage.setItem('locale', 'en');
  } else {
    localStorage.setItem('locale', 'id');
  }
  const locale = localStorage.getItem('locale');
  const translations = require(`raw-loader!./locale/messages.${locale}.xlf`).default;

platformBrowserDynamic().bootstrapModule(AppModule, {
  providers: [
    {provide: TRANSLATIONS, useValue: translations},
    {provide: TRANSLATIONS_FORMAT, useValue: 'xlf'}
  ]
});


