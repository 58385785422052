<app-page-header pageTitle="{{'broadcast.flexBroadcast' | translate}}" EngTitle="Broadcast">
	<div class="col-md-12 col-sm-12 col-xs-12 conversation" style="padding-left:0px;">
		<div class="page-loader" *ngIf="loaderShow">
			<img src="assets/images/dashboard/uib-loading-500x500.gif" style="width: 100px" alt="">
		</div>
		<div style="height: 390px;margin-bottom: 25px;">
			<div class="col-md-12 col-sm-12 col-xs-12 sep parent" style="background-color:#fff;padding:20px;">
				<!--BROADCAST TYPE WHATSAPP-->
				<div>
					<!--CHOOSE TEMPLATE-->
					<div class="row row-bottom" style="margin-top:15px">
						<div class="col-md-4 col-sm-4 col-xs-4">
							<h4 i18n="@@chooseTemplate">{{ 'broadcast.choose_template' | translate}}</h4>
						</div>
						<div class="col-md-8 col-sm-8 col-xs-8 responsive-input" style="margin-top:-3px">
							<p class="col-md-7 col-sm-7 col-xs-5" style="padding-left:0px;">
								<span class="coversation-date-span">
									<ngx-select-dropdown tabindex="0" [multiple]="false" [(ngModel)]="flexMessageModel" [config]="config" [options]="flexmessages" (change)="chooseFlexSelectionChanged()" ></ngx-select-dropdown>
								</span>
							</p>
						</div>
					</div>
					<!--TEMPLATE CONTENT-->
					<div class="row row-bottom" style="margin-top:17px">
						<div class="col-md-4 col-sm-4 col-xs-4"></div>
						<div class="col-md-8 col-sm-8 col-xs-8">
							<p class="col-md-7 col-sm-7 col-xs-5" style="padding-left:0px;" *ngIf="templateContent">
								<span class="coversation-date-span">
									<textarea [disabled]="true" style="height: 100px;max-height: 300px;overflow: scroll;padding: 5px;background: #fff; border-radius: 2px !important;width: 100% !important;" [(ngModel)]="templateContent"></textarea>
								</span>
							</p>
						</div>
					</div>

					<!--MESSAGING LIST AND RECEPIENT-->
					<mat-radio-group aria-label="Select an option" [(ngModel)]="messageOption" (change)="messageOptionChange($event)">
						<div class="row row-bottom" style="margin-top:15px">
							<div class="col-md-4 col-sm-4 col-xs-4">
								<mat-radio-button value="recipient">
									<h4 style="margin-top:12px" i18n="@@recipient">{{ 'broadcast.recipient' | translate}}</h4>
								</mat-radio-button>
							</div>

							<div class="col-md-8 col-sm-8 col-xs-8 responsive-input" style="padding-top:5px">
								<p class="col-md-7 col-sm-7 col-xs-5" style="padding-left:0px;">
									<span class="coversation-date-span">
										<input placeholder="Enter Line User ID" [(ngModel)]="recipientModel"
											[disabled]="messageOption != 'recipient'" class="form-control form-cotrol-question"
											style="height: 36px;border: 1px solid #c0c0c0;" name="recipient">
									</span>
								</p>
								<!-- <p style="font-size: 12px;color: #828282;">
									<em i18n="@@phoneNumbercountryCode"> {{ 'common.phoneNo_with_country_code' | translate}}</em>
								</p> -->
							</div>
						</div>
					</mat-radio-group>
				</div>

				<!--SCHEDULE MESSAGE-->
				<div class="row row-bottom" style="margin-top:5px;">
					<div class="col-md-4" style="padding-top:17px;left: 20px;">
						<h4 i18n="@@send">{{ 'common.send' | translate}}</h4>
					</div>
					<div class="col-md-8">
						<mat-radio-group name="sendtype" aria-label="Select Broadcast method" [(ngModel)]="send" (change)="selectBroadcast()">
							<div class="col-md-2 col-sm-2 col-xs-6" style="padding:15px;border-radius:5px;width:30%;padding-left:0px;">
								<mat-radio-button value="now" [checked]="send !== '' && send == 'now' ? true : false" i18n="@@now"> {{ 'broadcast.now' | translate}} </mat-radio-button>
							</div>
							<div class="col-md-2 col-sm-2 col-xs-6" style="padding:15px;border-radius:5px;float:left;width:40%;margin-right:72px;">
								<mat-radio-button value="schedule" [checked]="send !== '' && send == 'schedule' ? true : false" i18n="@@schedule"> {{ 'broadcast.schedule' | translate}}</mat-radio-button>
								<br />
							</div>
						</mat-radio-group>
					</div>
				</div>

				<!--CALENDAR-->
				<div class="row row-bottom" style="margin-top:15px">
					<div class="col-md-4">
					</div>

					<div class="col-md-5" *ngIf="send=='schedule'">
						<div class="row row-bottom">
							<div class="col-xs-12 col-sm-12 col-md-12">
								<div class="col-xs-12 col-sm-6 col-md-5 col-lg-5">
									<div style="display: flex;">
										<em style="font-size: 25px;color: #6b6a6a;margin-top:2px;" class="fa fa-calendar"></em>
										<input type="text" placeholder="{{'common.date_picker' | translate}}" class="form-control btn broadcastPicker" [minDate]="minDate" bsDatepicker [bsConfig]="{ isAnimated: true ,containerClass: 'theme-dark-blue'}" placement="top" [(ngModel)]="bsInlineValue" style="height:30px;border-radius: 5px !important;margin-left:4px;border: 1px solid #a09f9f;">
									</div>
								</div>

								<div class="col-xs-12 col-sm-6 col-md-7 col-lg-7 responsive-hour-div" style="display: inline-flex;">
									<div class="col-sm-4 col-md-4">
										<select [(ngModel)]="timeHH" style="width:65px;height:30px;overflow:scroll;padding-right: 1px; margin-left:-22px; border-radius: 5px !important;border: 1px solid #a09f9f;" class="form-control">
											<option value="">HH</option>
											<option value="00">00</option>
											<option value="01">01</option>
											<option value="02">02</option>
											<option value="03">03</option>
											<option value="04">04</option>
											<option value="05">05</option>
											<option value="06">06</option>
											<option value="07">07</option>
											<option value="08">08</option>
											<option value="09">09</option>
											<option value="10">10</option>
											<option value="11">11</option>
											<option value="12">12</option>
											<option value="13">13</option>
											<option value="14">14</option>
											<option value="15">15</option>
											<option value="16">16</option>
											<option value="17">17</option>
											<option value="18">18</option>
											<option value="19">19</option>
											<option value="20">20</option>
											<option value="21">21</option>
											<option value="22">22</option>
											<option value="23">23</option>
										</select>
									</div>

									<div class="col-sm-4 col-md-4">
										<select [(ngModel)]="timeMM" style="width: 65px;height: 30px;overflow:scroll;border-radius: 5px !important;border: 1px solid #a09f9f;padding-right:1px;" class="form-control">
											<option value="">MM</option>
											<option value="00">00</option>
											<option value="15">15</option>
											<option value="30">30</option>
											<option value="45">45</option>
										</select>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div style="margin-top:40px;text-align:center;">
					<button type="button" style="cursor: pointer;"  [ngClass]="[send && send.length > 0 ? 'commonButton btn-blue':'btnDisable btn-grey']" [disabled]="!(send && send.length > 0)"  (click)="broadcastFlexMessage()" i18n="@@broadcast">{{ 'broadcast.broadcast' | translate}}</button>
				</div>
			</div>
		</div>
	</div>
</app-page-header>

