<div class="page-margin-top" >
  <div class="content-top clearfix">
    <h1 class="al-title headingMain">
      <img *ngIf="EngTitle && getMenuIcon(EngTitle) !== ''" class="menu-side-icon" [src]="getMenuIcon(EngTitle)" style="height :26px;width:26px;" alt="" />
      &nbsp;{{pageTitle}}
    </h1>
    <a (click)="back()" *ngIf="goBack">
      <em class="fa fa-angle-left" style="float: left;color:#828282; font-size: 34px;"></em>
      <h1 class="al-title headingMain" style="color: #828282;font-size: 16px;	padding: 10px;">Go back</h1>
    </a>
  </div>
  <ng-content></ng-content>
</div>
