import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';

import { Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { SharedsService } from 'src/app/shareds/shareds.service';
import { ExceptionHandlerService } from 'src/app/exception-handler.service';
import { EnvironmentService } from '../environment.service';

@Injectable({
  providedIn: 'root',
})
export class NlpService {
  apiUrl: string;
  httpOptions: any;

  constructor(
    private http: HttpClient,
    private envService: EnvironmentService,
    private sharedService: SharedsService,
    private expService: ExceptionHandlerService
  ) {
    this.apiUrl = this.envService.read('apiUrl');
    this.setHttpHeaders();
  }
  private handleError(err: HttpErrorResponse): Observable<any> {
    // handle your auth error or rethrow
    if (err && (err.status === 401 || err.status === 500)) {
        return of(err.error);
    }
    throw err.error;
}
  sendNlp(args): Observable<any> {
    args.appId = this.sharedService.appId;
    args.timeZone = this.sharedService.timeZone;
    this.httpOptions.params = null;
    return this.http.post<any>(`${this.apiUrl}/dashboard/set_app_credentials`, args,
        this.httpOptions).pipe(
            tap(),
            catchError((error) => {
             return this.handleError(error);
            })
        );
  }



validateGpt3(args): Observable<any> {
  args.appId = this.sharedService.appId;
  args.timeZone = this.sharedService.timeZone;
  this.httpOptions.params = null;
  this.httpOptions.params = new HttpParams()
    .set('appId', this.sharedService.appId)
    .set('gpt3Key', args.gpt3Key)
    .set('timeZone', this.sharedService.timeZone);
  return this.http.get<any>(`${this.apiUrl}/dashboard/gpt3/validate`, this.httpOptions)
  .pipe(
    tap(), catchError((error) => {
      return this.handleError(error);
  })
  );
}

  getAppCredentials(): Observable<any> {
    this.httpOptions.params = new HttpParams().set(
      'appId',
      this.sharedService.appId
    ).set('timeZone', this.sharedService.timeZone);
    return this.http
      .get<any>(`${this.apiUrl}/getAppSettings`, this.httpOptions)
      .pipe(
        tap(),
        catchError((error) => {
          return this.handleError(error);
        })
      );
  }

  getAppChannelSettings(): Observable<any> {
    this.httpOptions.params = new HttpParams().set(
      'appId',
      this.sharedService.appId
    ).set('timeZone', this.sharedService.timeZone);
    return this.http
      .get<any>(`${this.apiUrl}/getAppChannelSettings`, this.httpOptions)
      .pipe(
        tap(),
        catchError((error) => {
          return this.handleError(error);
        })
      );
  }

  setHttpHeaders() {
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: this.envService.read('Authorization'),
        'access-token': this.sharedService.token,
      }),
    };
  }

  validateNlp(args, type): Observable<any> {
    if (type === 'OpenAI' || type === 'gptJ') {
    this.httpOptions.params = new HttpParams()
      .set('appId', this.sharedService.appId)
      .set('gpt3Key', args.gpt3Key)
      .set('gpt3QASheetName', args.gpt3QASheetName)
      .set('gpt3sheetId', args.gpt3sheetId)
      .set('gpt3ConversationSheetName', args.gpt3ConversationSheetName)
      .set('gpt3_wordcount', args.gpt3_wordcount)
      .set('gpt3QASheetNames', JSON.stringify(args.gpt3QASheetNames))
      .set('gptType', type)
      .set('gptJKey', args.gptJKey)
      .set('gptJ_wordcount', args.gptJ_wordcount)
      .set('timeZone', this.sharedService.timeZone);
    } else {
      this.httpOptions.params = new HttpParams()
      .set('appId', this.sharedService.appId)
      .set('gptType', type)
      .set('timeZone', this.sharedService.timeZone);
    }
    return this.http
      .get<any>(
        `${this.apiUrl}/dashboard/spreadsheet/validate`,
        this.httpOptions
      )
      .pipe(
        tap(),
        catchError((error) => {
          return this.handleError(error);
        })
      );
  }
}
