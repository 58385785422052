import { Component, Input, OnInit } from '@angular/core';
import { environment } from '../../../../../environments/environment';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { IconsService } from '../icons.service';

@Component({
  selector: 'app-page-header',
  templateUrl: './page-header.component.html',
  styleUrls: ['./page-header.component.scss']
})
export class PageHeaderComponent implements OnInit {
  @Input() pageTitle;
  @Input() EngTitle;
  @Input() goBack = false;
  dashboardPrefix = environment.dashboardPrefix;
  currentRoute: string;
  appAgentHandover = false;

  constructor(private location: Location, private router: Router, private iconsService: IconsService) {
  }

  ngOnInit() {
    this.currentRoute = this.router.url;
    this.appAgentHandover = JSON.parse(localStorage.getItem('user'))?.appSettings.conversations.agent_handover ? JSON.parse(localStorage.getItem('user')).appSettings.conversations.agent_handover : false;
  }

  back() {
    this.location.back();
  }

  getMenuIcon(name) {
    if (name) {
      return this.iconsService.getMenuIcon(name);
    } else {
      return '';
    }
}

}
