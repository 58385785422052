import { HttpErrorResponse } from '@angular/common/http';
import { Injectable, ErrorHandler, Injector } from '@angular/core';
import * as Sentry from '@sentry/browser';
import { environment } from '../environments/environment';

Sentry.init({
  environment: environment.environment,
  dsn: environment.sentryKey,
  ignoreErrors: [
    'Non-Error exception captured'
  ]
});

@Injectable({
  providedIn: 'root'
})
export class ExceptionHandlerService implements ErrorHandler {
  constructor(private injector: Injector) { }

  handleError(error: any) {
    if (environment.production) {
      Sentry.captureException(error.originalError || error);
      if (Error instanceof HttpErrorResponse) {
        console.log(error.status);
      } else {
        console.error('Capture Exception and send to Sentry successfully', error);
      }
    } else {
      console.log('sentry error', error);
    }
  }
}
