import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, of, tap } from 'rxjs';
import { EnvironmentService } from 'src/app/environment.service';
import { ExceptionHandlerService } from 'src/app/exception-handler.service';
import { SharedsService } from 'src/app/shareds/shareds.service';

@Injectable({
  providedIn: 'root'
})
export class QuickreplyService {
  apiUrl: string;
  httpOptions: any;

  constructor(
    private http: HttpClient,
    private envService: EnvironmentService, private sharedService: SharedsService, private expService: ExceptionHandlerService
  ) { 
    this.apiUrl = this.envService.read('apiUrl');
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: this.envService.read('Authorization'),
        'access-token': this.sharedService.token,
      }),
    };

  }


  addQuickReply(args): Observable<any> {
    this.httpOptions.params = null;
    args.appId = this.sharedService.appId;
    return this.http.post<any>(`${this.apiUrl}/dashboard/quickReply/save`, args,
        this.httpOptions).pipe(
            tap(),
            catchError((error) => {
              this.expService.handleError(error);
                return of(error);
            })
        );
  }

  getQuickReplyList( args): Observable<any> {
    this.httpOptions.params = new HttpParams()
      .set('appId', args.appId )
      .set('search', args.search)
      .set('limit', args.limit)
      .set('skip', args.skip);
    return this.http.get<any>(`${this.apiUrl}/dashboard/quickReply/list`,
      this.httpOptions).pipe(
        tap(),
        catchError((error) => {
          this.expService.handleError(error);
          return of(error);
        })
      );
  }
  updateQuickReply(args): Observable<any> {
    this.httpOptions.params = null;
    args.appId = this.sharedService.appId;
    return this.http.post<any>(`${this.apiUrl}/dashboard/quickReply/update`, args,
      this.httpOptions).pipe(
        tap(),
        catchError((error) => {
          this.expService.handleError(error);
          return of(error);
        })
      );

  }
  deleteQuickReply(args): Observable<any> {
    this.httpOptions.params = null;
    args.appId = this.sharedService.appId;
    return this.http.post<any>(`${this.apiUrl}/dashboard/quickReply/delete`, args,
      this.httpOptions).pipe(
        tap(),
        catchError((error) => {
          this.expService.handleError(error);
          return of(error);
        })
      );

  }

  searchQuickReplyUsers(term, arg) {
    this.httpOptions.params = new HttpParams()
      .set('search', term)
      .set('limit', arg.limit)
      .set('skip', arg.skip)
      .set('appId', this.sharedService.appId)
      .set('timeZone', this.sharedService.timeZone);
    return this.http
      .get<any>(`${this.apiUrl}/dashboard/quickReply/search`, this.httpOptions)
      .pipe(
        tap(),
        catchError((error) => {
          this.expService.handleError(error);
          return of(error);
        })
      );
  }

}
