import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { LoginService } from './login/login.service';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService  {

  constructor(private loginService: LoginService, private router: Router) { }

  previousUrl: string;
  beforePreviousUrl: string;

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const url: string = state.url;
    const dashboardPrefix = environment.dashboardPrefix === '/' ? '' : environment.dashboardPrefix;
    if (url === dashboardPrefix + '/conversationalelements' ) {
      this.conversationalElementsURL(dashboardPrefix);
    }
      if (url === dashboardPrefix + '/settings' ) {
        this.settingsURL(dashboardPrefix);
      }
      this.agentAllocationURL(dashboardPrefix, url);
      this.conversationsURL(dashboardPrefix, url);
      return this.checkLogin(url);
  }
  conversationsURL(dashboardPrefix, url) {
    if (url === dashboardPrefix + '/conversations') {
    const projectData = JSON.parse(localStorage.getItem('user'));
    if (projectData ?.appSettings ?.projectDetail ?.is_internal_tg_group) {
      this.router.navigate([dashboardPrefix + '/livegroupconversations']);
    }
  }
  }
  agentAllocationURL(dashboardPrefix, url) {
    if (url === dashboardPrefix + '/agentallocation' ) {
    let index = 1;
    const userData = JSON.parse(localStorage.getItem('user'));
    const menutab = JSON.parse(localStorage.getItem('menutab')) ? JSON.parse(localStorage.getItem('menutab')) : '';
    if (menutab !== '') {
        index = menutab.findIndex(item => item.name === 'Agent Routing');
        console.log('Agent Routingindexindex', index);
    } else {
        index = userData?.roles?.menu_tab.findIndex(item => item.name === 'Agent Routing');
        console.log('Agent Routingindexindex22', index);
    }
    if (userData?.appid !== '9dafbbca-47ba-4b02-b45a-dba95bd1c3c4' &&
    (userData?.user_type !== 'master' && userData?.appid === 'ec13ec8e-99b2-4b70-94ae-610694d2a7e0' ) || (index === -1) ) {
      const redirectUrl = this.beforePreviousUrl ? this.beforePreviousUrl : userData?.roles?.menu_tab[0].path;
      this.router.navigate([dashboardPrefix + redirectUrl]);
    }
  }
  }
  conversationalElementsURL(dashboardPrefix) {
    const userData = JSON.parse(localStorage.getItem('user'));
    if (userData?.user_type !== 'master' && userData?.user_type !== 'developer' && userData?.user_type !== 'agent') {
      const redirectUrl = this.beforePreviousUrl ? this.beforePreviousUrl : userData?.roles?.menu_tab[0].path;
      this.router.navigate([dashboardPrefix + redirectUrl]);
    }
  }
  settingsURL(dashboardPrefix) {
    const userData = JSON.parse(localStorage.getItem('user'));
    if (userData?.user_type !== 'master' && userData?.user_type !== 'developer' ) {
      const redirectUrl = this.beforePreviousUrl ? this.beforePreviousUrl : userData?.roles?.menu_tab[0].path;
      this.router.navigate([dashboardPrefix + redirectUrl]);
    }
  }
  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    return this.canActivate(route, state);
  }

  checkLogin(url: string): boolean {
    if (this.loginService.isLoggedIn(url)) {
      return true;
    }

    const dashboardPrefix = environment.dashboardPrefix === '/' ? '' : environment.dashboardPrefix;

    if (url === dashboardPrefix + '/login') {
      const userData = JSON.parse(localStorage.getItem('user'));
      if (userData) {
        const redirectUrl = this.beforePreviousUrl ? this.beforePreviousUrl : userData?.roles?.menu_tab[0].path;
        const tabClose = sessionStorage.getItem('tabClose');
        if (tabClose === 'close') {
          return true;
        }
        this.router.navigate([dashboardPrefix + redirectUrl]);
      } else {
        return true;
      }
    } else {
      // Store the attempted URL for redirecting
      this.loginService.redirectUrl = url;
      // Navigate to the login page with extras
      this.router.navigate([dashboardPrefix + '/login']);
    }

    return false;
  }
}
