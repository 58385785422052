import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpHandler, HttpRequest, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Router} from '@angular/router';


@Injectable()
export class Interceptor implements HttpInterceptor {
  constructor(private router: Router ) {

  }
    intercept(
        req: HttpRequest<any>,
        next: HttpHandler
      ): Observable<HttpEvent<any>> {
        let appId: any = '';
        const userData = JSON.parse(localStorage.getItem('user'));
        const chanel = localStorage.getItem('chanel');
        const url: string = this.router.url;
        if ( !url.includes('/flow-builderv2') && !url.includes('/flow-builderv3') && !url.includes('/register') && chanel !== 'twitter') {
          if (userData) {
            appId = userData.appid;
          }
          const duplicate = req.clone({ params: req.params.set('appId', appId) });
          return next.handle(duplicate);
        } else {
      return next.handle(req);
    }
  }
}
// }
