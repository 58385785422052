import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { Login, LoginResponse } from './login';
import { environment } from '../../../environments/environment';
import { EnvironmentService } from '../../environment.service';
import { ExceptionHandlerService } from 'src/app/exception-handler.service';

@Injectable({
  providedIn: 'root',
})
export class LoginService {
  redirectUrl: string;
  err = new LoginResponse();
  loginUrl;
  userData;
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };
  timeZone: any;
  login(user: Login): Observable<LoginResponse> {
    this.err = {
      success: false,
      message: 'Something went wrong',
      user_data: null,
    };
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Basic ${btoa(user.username + ':' + user.password)}`,
      }),
    };
    return this.http
      .post<LoginResponse>(
        `${this.loginUrl}/dashboard/user/login`,
        user,
        this.httpOptions
      )
      .pipe(tap(), catchError(this.handleError('login', this.err)));
  }

  /* ------------- dont remove this code -------------
      login(user: Login): Observable<LoginResponse> {
        this.err = { success: false, message: 'Something went wrong',locale:'' ,user_data: null };
        this.httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': `Basic ${btoa(user.username + ':' + user.password)}`
            })
        };
        return this.http.post<LoginResponse>(`${this.loginUrl}/dashboard/user/login`, user, this.httpOptions).pipe(
            // tap(data =>  this.isLoggedIn = true),
            tap(),
            catchError(this.handleError('login', this.err))
        );
    } */

  getLanguage() {
    return 'fr';
  }

  logout(): Observable<any> {
    const userData = JSON.parse(localStorage.getItem('user'));
    const token = userData?.token ?? '';
    this.loginUrl = this.envService.read('apiUrl');
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: this.envService.read('Authorization'),
        'access-token': token,
      }),
    };
    return this.http
      .post<any>(`${this.loginUrl}/dashboard/user/logout`, '', this.httpOptions)
      .pipe(
        tap(),
        catchError((error) => {
          this.expService.handleError(error);
          return of(error);
        })
      );
  }

  getToken() {
    return JSON.parse(localStorage.getItem('user')).token;
  }

  isLoggedIn(url: string): boolean {
    const dashboardPrefix =
      environment.dashboardPrefix === '/' ? '' : environment.dashboardPrefix;
    if (url === dashboardPrefix + '/login') {
      try {
        const userData = JSON.parse(localStorage.getItem('user'));
        return !userData && userData.user_name;
      } catch (e) {
        return true;
      }
    } else {
      const userData = JSON.parse(localStorage.getItem('user'));
      return !!userData && userData.user_name;
    }
  }

  constructor(
    private http: HttpClient,
    private envService: EnvironmentService,
    private expService: ExceptionHandlerService
  ) {
    this.userData = JSON.parse(localStorage.getItem('user')) ? JSON.parse(localStorage.getItem('user')) : {};
    const token = this.userData.token ? this.userData.token : '';
    this.loginUrl = this.envService.read('apiUrl');
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: this.envService.read('Authorization'),
        'access-token': token,
      }),
    };
    this.timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  }

  resetPassword(args): Observable<any> {
    args.timeZone = this.timeZone;
    const token = this.userData.token ? this.userData.token : '';
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: this.envService.read('Authorization'),
        'access-token': token,
      }),
    };
    return this.http
      .post<any>(
        `${this.loginUrl}/dashboard/password/update`,
        args,
        this.httpOptions
      )
      .pipe(tap(), catchError(this.handleError('reset', this.err)));
  }

  forgotPassword(args): Observable<any> {
    args.timeZone = this.timeZone;
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };
    return this.http
      .post<any>(
        `${this.loginUrl}/dashboard/forgotpassword`,
        args,
        this.httpOptions
      )
      .pipe(
        tap(),
        catchError((error) => {
          this.handleError(error);
          return of(error);
        })
      );
  }

  onLoadSetPassword(args: any) {
    args.timeZone = this.timeZone;
    return this.http.post<any>(`${this.loginUrl}/onloadsetpassword`, args).pipe(
      map((data) => {
        return data;
      })
    );
  }

  setPassword(args: any) {
    args.timeZone = this.timeZone;
    return this.http.post<any>(`${this.loginUrl}/setpassword`, args).pipe(
      map((data) => {
        return data;
      })
    );
  }

  licenseValidation(args): Observable<any> {
    this.loginUrl = this.envService.read('apiUrl');
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: this.envService.read('Authorization')
      }),
    };
    return this.http
      .post<any>(`${this.loginUrl}/channelOnboard/token/verify`, args, this.httpOptions)
      .pipe(
        tap(),
        catchError((error) => {
          this.expService.handleError(error);
          return of(error);
        })
      );
  }

  userSignup(args): Observable<any> {
    this.loginUrl = this.envService.read('apiUrl');
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: this.envService.read('Authorization')
      }),
    };
    return this.http
      .post<any>(`${this.loginUrl}/channelOnboard/signUp`, args, this.httpOptions)
      .pipe(
        tap(),
        catchError((error) => {
          this.expService.handleError(error);
          return of(error);
        })
      );
  }
  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      return of(result);
    };
  }
  sendGoogleToken(args): Observable<any> {
    args.timeZone = this.timeZone;
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };
    return this.http
      .post<any>(
        `${this.loginUrl}/verify-token`,
        args,
        this.httpOptions
      )
      .pipe(
        tap(),
        catchError((error) => {
          this.handleError(error);
          return of(error);
        })
      );
  }
  sendLinkedinToken(arg): Observable<any> {
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };
    return this.http
      .post<any>(
        `${this.loginUrl}/auth/linkedin/callback`,
        arg,
        this.httpOptions
      )
      .pipe(
        tap(),
        catchError((error) => {
          this.handleError(error);
          return of(error);
        })
      );
  }
}
