<div class="inline" toastContainer></div>
<div class="notification" style="position: relative;" (window:resize)="initResponsiveElements()">
   <span style="position: absolute; top: 15px;left: 15px;color: #828282;font-size: 16px; line-height: 19px;" *ngIf="headerNotifications.length>3">+{{headerNotifications.length-3}}</span>
</div>
<div class="bColor">
   <div ngbDropdown class="ngbDropdownClass">
      <ul class="linkColor al-msg-center clearfix support-dropdown" style="display:flex;">
         <li *ngIf="checkWindowSize()" style="margin:auto;font-size: 12px;margin-left: 10px;width:65%;">
            <a [routerLink]="" (click)="editProfile();" *ngIf="userType == 'master'" id="msgCenterWindowSizeEditPro">
              <p>{{firstWord(userName)}}</p>
            </a>
            <a [routerLink]=""  *ngIf="userType == 'developer'" id="msgCenterWindowSizeUserDev">
               {{firstWord(userName)}}
            </a>
            <a [routerLink]=""  *ngIf="userType == 'agent'" id="msgCenterWindowSizeUserAgent">
               {{firstWord(userName)}}
            </a>
         </li>
         <li *ngIf="!checkWindowSize()" style="margin:auto;font-size: 12px;margin-left: 10px;width:65%;text-align:end;">
            <p [routerLink]=""  (click)="editProfile();" (keydown)="editProfile();" *ngIf="userType == 'master'" id="msgCenterEditProMaster" style="cursor: pointer;">
              {{userName}}
            </p>
            <p [routerLink]=""  *ngIf="userType == 'developer'" id="msgCenterUserNameDev" style="cursor: pointer;">
               {{userName}}
            </p>
            <p [routerLink]=""  *ngIf="userType == 'agent'" id="msgCenterUserNameAgent" style="cursor: pointer;">
               {{userName}}
            </p>
            <p [routerLink]=""  *ngIf="userType == 'supervisor'" id="msgCenterUserNameSupervisor" style="cursor: pointer;">
              {{userName}}
           </p>
         </li>
         <li ngbDropdown style="margin:auto;">
            <a ngbDropdownToggle (click)="countUpdate();" *ngIf="resultList.length" id="msgCenterNotificationCount">
               <em class="fa fa-bell" style="font-size: 18px;cursor: pointer;"></em>
               <span *ngIf="count > 0">{{count}}</span>
            </a>
            <div ngbDropdownMenu class="top-dropdown-menu notification-main" id="msgCenterNotificationDropMenu">
               <div class="courses-list msg-list" *ngIf="!closeNotify" infiniteScroll [scrollWindow]="false" (scrolled)="loadMoreCourses()">
                  <div style="padding: 10px 12px;display: block;text-decoration: none;color: #000;font-size: 12px;" *ngFor="let notification of resultList"  [class.notification-lists-read]="!notification.read">
                     <a [routerLink]=""  class="clearfix" (click)="notificationRead(notification)" id="msgCenterNotificationRead">
                        <div class="img-area"></div>
                        <div class="msg-area">
                            <div>
                                <p style="font-size:12px;">
                                    <em *ngIf="notification.action=='approveQuestion'" class="fa fa-check-circle"   [style.color]="applyfontcolor(notification.action)" style="float:left;margin-top: 2px;"></em>
                                    <em *ngIf="notification.action=='rejectQuestion'" class="fas fa-times-circle"   [style.color]="applyfontcolor(notification.action)" style="float:left;margin-top: 2px;"></em>
                                    <em *ngIf="notification.action!='rejectQuestion' && notification.action!='approveQuestion'" class="fas fa-user-clock"   [style.color]="applyfontcolor(notification.action)" style="float:left;margin-top: 2px;"></em>
                                    &nbsp;{{notification.message}}

                                </p>
                            </div>
                            <div>
                              <p class="notification-bottom" style="float:right;margin-top: 5px;">
                                {{notification['modified_on'] | date: dateFormat}}
                              </p>
                            </div>
                        </div>
                    </a>

                    <div class="engage-sub" *ngIf="(appAgentHandover && routingAgentProject && notification['agent_id'].includes(user_id) && notification.category=='engageUser' && !notification.read && notification['is24']  && notification['categoryId'])
                    || (appAgentHandover && (randomAgentProject || routingAgentProject) && notification.category=='engageUser' && !notification.read && notification['is24']   && notification['categoryId'])
                    || (new_user_group_routing && groupAgent && appAgentHandover && (routingAgentProject || randomAgentProject) && notification.category=='engageUser' && notification['new_user'] == true && !notification['agent_id'].length && !notification.read && !notification['engage_enabled'] && (notification['engager_id'] == '' || notification['engagerId'] == '') && notification['is24'])
                    || (!new_user_group_routing  && appAgentHandover && (routingAgentProject || randomAgentProject) && notification.category=='engageUser' && notification['new_user'] == true && !notification['agent_id'].length && !notification.read && !notification['engage_enabled'] && (notification['engager_id'] == '' || notification['engagerId'] == '')&& notification['is24'])" style="margin-top: -8%;margin-left: 3%;float: left;z-index: 999;width: 80px;">
                        <span (click)="notificationEngage(notification, undefined, $event);" (keydown)="notificationEngage(notification, undefined, $event);" id="msgCenterNotificationAgentEngage">
                          <i class="fa fa-comments" style="color: white;" aria-hidden="true" >
                            <span style="display: inline-flex;" >
                              &nbsp;{{'msg_center.engage' | translate}}
                            </span>
                          </i>
                        </span>
                    </div>
                    <div class="already-engage-sub" *ngIf="(appAgentHandover && routingAgentProject && notification['agent_id'].includes(user_id) &&  notification.category=='engagedUserMessage'  &&  notification['is24']  && notification['categoryId'])
                        || (appAgentHandover && (routingAgentProject || randomAgentProject) && notification.category=='engagedUserMessage' && notification['is24']  && notification['categoryId'])
                        || (appAgentHandover && (routingAgentProject || randomAgentProject) &&  notification.category=='engagedUserMessage'  && (notification.read || (notification['engager_id'] == user_id || notification['engagerId'] == user_id))  && notification['engage_enabled'] && notification['is24'])  "style="margin-top: -8%;margin-left: 3%;float: left;z-index: 999;width: 80px;">
                          <span (click)="notificationEngage(notification, undefined, $event);" (keydown)="notificationEngage(notification, undefined, $event);" id="msgCenterNotificationAgentHandover">
                            <i class="fa fa-comments" style="color: #000;" aria-hidden="true" >
                              <span style="display: inline-flex;" >
                                &nbsp;{{'msg_center.engaged' | translate}}
                              </span>
                            </i>
                          </span>
                    </div>
                    </div>
                    <div style="text-align:center;width:100%;padding: 5px;">
                        <img alt="" style="text-align:center;width:45px;" src="assets/images/uib-loader.gif" *ngIf="notificationSpinner">
                    </div>
                </div>
            </div>
         </li>
         <li ngbDropdown style="margin:auto;">
            <a [routerLink]="" class="msg" ngbDropdownToggle id="msgCenterProfileClick">
               <em  class="fas fa-ellipsis-v" style="cursor: pointer;" (click)="profileclick();" (keydown)="profileclick();"></em>
            </a>
            <div ngbDropdownMenu class="top-dropdown-menu setting-dropdown profile-popup" id="msgCenterSettingDropdowan">
               <div class="navDrop header clearfix" style="font-size: 12px;">
                  <p>
                     <a [routerLink]="" (click)="editProfile();" *ngIf="userType == 'master'" id="msgCenterEditProfile">
                        <i class="fa fa-user" aria-hidden="true" style="padding-right: 5px;"></i>{{userName}}<br>
                        <span style="font-size:10px;padding-left:18px;text-transform: capitalize;" i18n="@@master">(Admin)</span>
                     </a>
                      <a [routerLink]="" (click)="editProfile();" *ngIf="userType == 'supervisor'" id="msgCenterEditProfile1">
                        <i class="fa fa-user" aria-hidden="true" style="padding-right: 5px;"></i>{{userName}}<br>
                        <span style="font-size:10px;padding-left:18px;text-transform: capitalize;" i18n="@@master">(Supervisor)</span>
                      </a>
                     <a [routerLink]="" *ngIf="userType == 'developer'" id="msgCenterUserDeveloper">
                        <i class="fa fa-user" aria-hidden="true" style="padding-right: 5px;"></i>{{userName}}<br>
                        <span style="font-size:10px;padding-left:18px;text-transform: capitalize;" i18n="@@developer">({{userType}})</span>
                     </a>
                     <a [routerLink]="" (click)="editProfile();" *ngIf="userType == 'agent'" id="msgCenterUserAgent">
                        <i class="fa fa-user" aria-hidden="true" style="padding-right: 5px;"></i>{{userName}}<br>
                        <span style="font-size:10px;padding-left:18px;text-transform: capitalize;" i18n="@@agent">({{userType}})</span>
                     </a>
                  </p>
                  <p  *ngIf="(userType == 'master' || userType == 'developer') && showSettings" >
                     <a [routerLink]="" (click)="settingsClick()" id="msgCenterSetting">
                        <i class="fa fa-cog" aria-hidden="true" style="padding-right: 2px;"></i>
                        <span  i18n="@@settings"> {{'msg_center.settings' | translate}}</span></a>
                  </p>
                  <p  *ngIf="userType == 'master'" >
                     <a [routerLink]="" (click)="usersClick()" id="msgCenterUsers">
                        <i class="fa fa-users" aria-hidden="true" style="padding-right: 2px;font-size:smaller;"></i>
                        <span i18n="@@users"> {{'msg_center.users' | translate}}</span></a>
                  </p>
                  <p>
                     <a [routerLink]="" (click)="logout()" id="msgCenterLogout">
                        <i class="fa fa-sign-out" aria-hidden="true" style="padding-right: 2px;" ></i>
                        <span i18n="@@logout"> {{'msg_center.logout' | translate}}</span>
                     </a>
                  </p>
               </div>
            </div>
         </li>
      </ul>
   </div>
</div>
<app-resetpassword  [pwdBlock]="pwdBlock" [closeButton]="closeButton" (emitPass)="closeEvent($event)"></app-resetpassword>
<span i18n="@@toastr_disengageWindows"  name="disengageWindows" style="display: none;">{{'msg_center.disengage_all_chat' | translate}}</span>
