import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpHandler, HttpRequest, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';

@Injectable()
export class Interceptortoken implements HttpInterceptor {
  constructor(private router: Router ) {

  }
    intercept(
        req: HttpRequest<any>,
        next: HttpHandler
      ): Observable<HttpEvent<any>> {
        const userData = JSON.parse(localStorage.getItem('user')) ? JSON.parse(localStorage.getItem('user')) : false;
        if (userData.token) {
            return next.handle(
              req.clone({
                headers: req.headers.set('access-token', userData.token)
              })
            );
          } else {
      return next.handle(req);
    }
  }
}
