import { Component, Injector } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { SmartContactService } from '../home/smart-contact/smart-contact.service';
import { Subscription } from 'rxjs';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig } from '@angular/material/legacy-dialog';
import { IconsService } from '../home/shared/icons.service';
import { ToastrService } from 'ngx-toastr';
import { AppComponent } from 'src/app/app.component';
import { LoaderService } from 'src/app/loader/loader.service';
@Component({
  selector: 'app-digital-assistant',
  templateUrl: './digital-assistant.component.html',
  styleUrls: ['./digital-assistant.component.scss']
})
export class DigitalAssistantComponent {

  appId: string = null;
  redireectUrl: string;
  showTable = false;
  currentRoute: ActivatedRoute;
  router: Router;
  translate: TranslateService;
  translatorLanguage: any;
  panelTitle = '';
  private imageSrc = './assets/img/callicon.png';
  vcardUrl = './assets/vcard/unifiedai.vcf';
  image_url: any;
  uuid: any;
  contacts = [];
  defaultImage = './assets/img/default.jpg';
  currentId: string;
  qrcode_url: string;
  width: any;
  subscription: Subscription;
  // @Output() messageEvent = new EventEmitter<string>();
  screenWidth: number;
  channelList: any = [];
  configuredChannelsList: any = [];
  iconsService: IconsService;
  route: ActivatedRoute;
  loadershow = true;
  dialog: MatDialog;
  dialogRef: MatDialogConfig;
  user: any;
  smartContactService: SmartContactService;
  toastr: ToastrService;
  value: any;
  loader: LoaderService;
  constructor(private injector: Injector,
    private appComponet: AppComponent) {
    this.currentRoute = this.injector.get<ActivatedRoute>(ActivatedRoute);
    this.router = this.injector.get<Router>(Router);
    this.smartContactService = this.injector.get<SmartContactService>(SmartContactService);
    this.dialog = this.injector.get<MatDialog>(MatDialog);
    this.loader = this.injector.get<LoaderService>(LoaderService);
    this.translate = this.injector.get<TranslateService>(TranslateService);
    this.translatorLanguage = localStorage.getItem('Language');
    this.translate.setDefaultLang('en');
    this.translate.use(this.translatorLanguage ? this.translatorLanguage : 'en');
    this.iconsService = this.injector.get<IconsService>(IconsService);
    this.toastr = this.injector.get<ToastrService>(ToastrService);
    this.appId = this.currentRoute.snapshot.queryParamMap.get('id') ? this.currentRoute.snapshot.queryParamMap.get('id') : '';
    this.loader.display(true);
    if (!this.appId) {
      this.router.navigateByUrl('/login');
    } else {
      this.showTable = true;
      this.smartContactService.getProjectTheme(this.appId).subscribe(
        (res) => {
          if (res.status === 200 && res.info) {
            const theme = res.info;
            this.appComponet.loadStyle(theme);
            this.loader.display(false);
          } else {
            this.appComponet.loadStyle('black-style.css');
            this.loader.display(false);
          }
        } , (error) => {
          this.appComponet.loadStyle('black-style.css');
          this.loader.display(false);
        });
    }
  }

  ngOnInit() {
    this.loadershow = true;
    this.getConfiguredChannelsList();
    this.getChannelList();
    const args = {
      appId: this.appId
    };
    this.smartContactService.getQrCodeUrl(args).subscribe(
      (res) => {
        this.loadershow = false;
        if (res.code === 200) {
          this.qrcode_url = res?.info?.length && res.info[0].qrcode_url ? res.info[0].qrcode_url : './assets/img/qr_unifiedai.png';
        }
      },
      (err) => {
        this.loadershow = false;
        // DO Nothing
      }
    );
  }
  getConfiguredChannelsList() {
    this.smartContactService.getSmartContact(this.appId).subscribe((data) => {
      if (data.code === 200) {
        this.configuredChannelsList = data.info[0].channels;
        this.contacts = data.info;
      } else {
        this.configuredChannelsList = [];
      }
    });
  }
  getChannelList() {
    this.smartContactService.getChannelList(this.appId).subscribe((data) => {
      if (data.status === 200) {
        this.channelList = data.info ? data.info : [];
      } else {
        this.channelList = [];
      }
    });
  }

  applyfontcolor(channel: any): string {
    return this.iconsService.applyChannelIconColor(channel);
  }

  applyFontIcon(channel): string[] {
    return this.iconsService.applyChannelIcon(channel);
  }

  applyChannelName(channel) {
    return this.iconsService.applyChannelName(channel);
  }
  copy(value) {
    navigator?.clipboard?.writeText(value);
    this.toastr.success('', this.translate.instant('toastr.copied_to_clipboard'));
  }
  displayQrTemplate(TemplateRef, dataqr: any) {
    console.log('dataqr', dataqr);
    this.value = dataqr;
    this.dialogRef = this.dialog.open(TemplateRef, {
      width: '350px !important',
    });
  }


  onCancel() {
    if (this.dialogRef) {
      this.dialog.closeAll();
    }
  }
}
