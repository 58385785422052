import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { HomeService } from '../home.service';
import { Subscription } from 'rxjs';
import { MsgCenterService } from '../navbar/msg-center/msg-center.service';
import { Router, NavigationEnd } from '@angular/router';
import { environment } from '../../../../environments/environment';
import { SharedsService } from 'src/app/shareds/shareds.service';
import { SocketService } from '../socket.service';
import {TranslateService} from '@ngx-translate/core';
import { AppComponent } from 'src/app/app.component';

@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
    socketSubscription: Subscription;
    isAppEnabled = true;
    appName: string;
    appId: string;
    currentRoute: string;
    dashboardPrefix;
    routeSubscripion: Subscription;
    appAgentHandover = false;
    userAppNames: any;
    userApps = [];
    selectedApp;
    userType: string;
    userId: string;
    config: any = {
        displayKey: 'appName',
        search: true,
        height: 'auto',
        placeholder: 'Select',
        limitTo: 10,
        moreText: 'more',
        noResultsFound: 'No results found!',
        searchPlaceholder: 'Search',
        searchOnKey: 'appName'
      };

    @Output() messageEvent = new EventEmitter<string>();
    width: any; // width-true: Sidebar open, false - minimized
    screenWidth: number;
    subscription: Subscription;
    translatorLanguage: string;
    constructor(private router: Router, private homeService: HomeService, private msgCenterService: MsgCenterService
    , private sharedService: SharedsService, private socket: SocketService, public translate: TranslateService,
    private appComponet: AppComponent) {
        this.translatorLanguage = localStorage.getItem('Language');
        this.translate.setDefaultLang('en');
       const theme = localStorage.getItem('theme');

        this.appComponet.loadStyle(theme);
        // this.appComponet.loadStyle('black-style.css')
        this.translate.use(this.translatorLanguage ? this.translatorLanguage : 'en');

        this.subscription = this.homeService.getMessage().subscribe(data => {
            if (data.type === 'sideBar') {
                this.width = data.message;
            }
        });

        this.msgCenterService.menuToggle.subscribe(res => {
            this.toogleWidth('toggle');
        });
    }

    ngOnInit() {
        this.appName = JSON.parse(localStorage.getItem('user')).appname;
        this.appId = JSON.parse(localStorage.getItem('user')).appid;
        this.appAgentHandover = JSON.parse(localStorage.getItem('user')).appSettings.conversations.agent_handover ?
        JSON.parse(localStorage.getItem('user')).appSettings.conversations.agent_handover : false;
        this.initResponsiveElements();
        let width: any = localStorage.getItem('sideBarOpen');
        width = width ? (width  ===  'true') : true;
        this.width = width;
        this.homeService.sendMessage({
            type: 'sideBar',
            message: this.width
        });
        this.isAppEnabled = JSON.parse(localStorage.getItem('user')) && JSON.parse(localStorage.getItem('user')).appSettings ? JSON.parse(localStorage.getItem('user')).appSettings.projectDetail.is_app_enabled : true;
        this.dashboardPrefix = environment.dashboardPrefix === '/' ? '' : environment.dashboardPrefix;
        this.currentRoute = this.router.url;

        this.routeSubscripion = this.router.events.subscribe((e: any) => {
            // If it is a NavigationEnd event re-initalise the component
            if (e instanceof NavigationEnd) {
                this.currentRoute = this.router.url;
            }
        });

        this.userType = JSON.parse(localStorage.getItem('user')).user_type;
        this.userId = JSON.parse(localStorage.getItem('user')) && JSON.parse(localStorage.getItem('user')).user_id ? JSON.parse(localStorage.getItem('user')).user_id : '';
        const appNames = localStorage.getItem('allowedAppIds') ? localStorage.getItem('allowedAppIds') : '';
        this.userAppNames = appNames.split(',');
            const args = {
            userappnames: []
        };
        args.userappnames = this.userAppNames;

        this.msgCenterService.getAppNames(args).subscribe((result => {
            this.userApps = result.info;
            this.userApps = this.userApps.filter( app => {
                return(app.appid !== this.appId);
            });
            this.config = {
                displayKey: 'appName',
                search: true,
                height: 'auto',
                placeholder: this.appName,
                limitTo: this.userApps.length,
                moreText: 'more',
                noResultsFound: 'No results found!',
                searchPlaceholder: 'Search',
                searchOnKey: 'appName'
                };
        }));
        this.socketSubscription = this.socket.getApplicationStatus().subscribe((message) => {
            if (message.appid === this.appId) {
            this.isAppEnabled = message.is_app_enabled;
            } else { this.isAppEnabled = true; }
        });

    }
    // ngOnDestroy() {
    //     this.socketSubscription.unsubscribe();
    //   }
    toogleWidth(type: string) {
        if (type === 'toggle') {
          this.width = !this.width;
          localStorage.setItem('sideBarOpen', this.width.toString());
          this.homeService.sendMessage({
              type: 'sideBar',
              message: this.width
          });
        } else if (!(this.width.toString() === localStorage.getItem('sideBarOpen') ? localStorage.getItem('sideBarOpen').toString() : '' )) {
        localStorage.setItem('sideBarOpen', this.width.toString());
        this.homeService.sendMessage({
            type: 'sideBar',
            message: this.width
        });
    }
    this.messageEvent.emit(this.width);
}

changeuserApp() {
    const args = { appid : '', usertype: '', userid: '', lang: ''};
    const lang = localStorage.getItem('Language');
    args.appid = this.selectedApp.appid;
    args.usertype = this.userType;
    args.userid = this.userId;
    args.lang = lang;
    this.msgCenterService.getAppDetails(args).subscribe(result => {
       const local_data = JSON.parse(localStorage.getItem('user'));
       const theme = result.user_data?.['appSettings']?.['projectDetail']?.theme;

       localStorage.setItem('theme', theme || 'black-style.css');
        if (result.user_data.roles) {
            local_data.roles.menu_tab = result.user_data.roles.menu_tab;
        }
        if (result.user_data.appid) {
            local_data.roles.appid = result.user_data.appid;
            local_data.appid = result.user_data.appid;
        }
        if (result.user_data.appname) {
            local_data.roles.appname = result.user_data.appname;
            local_data.appname = result.user_data.appname;
        }
        if (result.user_data.channels) {
            local_data.channels = result.user_data.channels;
          }
        if (result.user_data.appSettings) {
            local_data.appSettings = result.user_data.appSettings;
            if (result.user_data.appSettings.AI_technology) {
                local_data.AI_technology = result.user_data.appSettings.AI_technology;
              } else {
                local_data.AI_technology = '';
            }
        }
        if (result.user_data.wabaAccounts) {
          local_data.wabaAccounts = result.user_data.wabaAccounts;
        }
        localStorage.setItem('companyNameLogoUrl', JSON.stringify(result.user_data.companyNameLogoUrl));
        localStorage.setItem('user', JSON.stringify(local_data));
        this.sharedService.appId = result?.user_data?.appid ?? '';
        const redirectUrl = environment.dashboardPrefix + local_data.roles.menu_tab[0].path;
                this.router.navigate([redirectUrl]);
                setTimeout(() => {
                    if (redirectUrl === this.router.url) {
                        this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
                            this.router.navigate(['/dashboard/overviews']);
                        });
                        // after setting modified menu_items calling side bar function to update menu tab.
                          this.homeService.sendMessage({
                               type: 'menutab'
                             });

                          this.homeService.sendMessage({
                               type: 'profile'
                              });
                    } else {
                            // after setting modified menu_items calling side bar function to update menu tab.
                            this.homeService.sendMessage({
                            type: 'menutab'
                            });

                            this.homeService.sendMessage({
                            type: 'profile'
                            });
                    }
                }, 1000);
    });
    }
    initResponsiveElements() {
        this.screenWidth = window.screen.width;
        if (this.screenWidth < 750) {
            this.width = false;
        } else {
            this.width = true;
        }
        this.toogleWidth('resize');
    }
}
