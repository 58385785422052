<app-page-header [pageTitle]="" [goBack]="true" EngTitle="">
    <div class="article-tree">
        <div>
            <div class="tree" style="margin-top: -55px;">
                <ul style="margin-left:10px;">
                    <li class="parent-li ng-scope ng-not-empty ng-valid" ng-model="displayTree">

                        <span></span>
                        <app-panel *ngIf="messageTitle" class="node-tree primary-tree" [panelStyle]="{ 'box-shadow': '0 8px 22px 0 rgba(0, 0, 0, 0.12)','margin-bottom': '25px' }">
                            <div class="panel-body" style="padding:0px; margin-top: 45px;">
                                <div class="col-md-1 sep parent" style="width:40px;box-shadow: none;padding: 8px;">
                                    <div style="position: absolute;">
                                        <i class="fa fa-angle-down" style="position: relative;top: 48px;" aria-hidden="true"></i>
                                    </div>
                                </div>
                                <div class="col-md-11 parent">
                                    <h2 class="conversation-questions" style="padding-top: 15px;">
                                        <span *ngIf="messageTitle.phone">{{messageTitle.phone | truncate:['75']}}</span>
                                        <span *ngIf="messageTitle.name">&bull; {{messageTitle.name}}</span>
                                        <span *ngIf="messageTitle.room_no">&bull; {{messageTitle.room_no}}</span>
                                    </h2>
                                    <p [title]="messageTitle.intents">{{messageTitle.intents | truncate:['115']}}</p>
                                    <p [title]="messageTitle.entities">{{messageTitle.entities | truncate:['115']}}</p>
                                    <div class="absolute">
                                        <div class="conversation-div">
                                            <span class="coversation-date-span">{{messageTitle.messages_count}}</span>
                                        </div>
                                        <div class="conversation-div" *ngFor="let item of messageTitle.connections">
                                            <i class="icon-green" [ngClass]="applyFontIcon(item['connectionType'])" aria-hidden="true"
                                *ngIf="item['connectionType']!=='bbm'"></i>
								<i  [ngClass]="applyFontIcon(item['connectionType'])" aria-hidden="true"
                                *ngIf="item['connectionType']==='bbm'"></i>
                            <span style="text-transform: capitalize;">
								{{item['connectionType'] === 'whatsappofficial' ? 'whatsapp' : item['connectionType']}}
							</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </app-panel>
                        <div class="row" *ngIf="conversations.length">
                            <div class="col-md-2 col-md-offset-8">
                                <p style="font-size: 12px;	color: #BDBDBD;margin:0;" i18n="@@list_intents">Intents</p>
                            </div>
                            <div class="col-md-2">
                                <p style="font-size: 12px;	color: #BDBDBD;margin:0;" i18n="@@list_objects">Objects</p>
                            </div>
                        </div>

                        <ul *ngIf="conversations.length">

                            <li class="parent-li " style="margin-right:0;padding-right:0;" *ngFor="let item of conversations">

                                <span></span>
                                <div class="col-md-12 conversation" style="padding-right: 0;padding-left: 0;">
                                    <app-panel class="node-tree" [panelStyle]="{ 'box-shadow': '0 8px 22px 0 rgba(0, 0, 0, 0.12)','margin-bottom': '25px' }">
                                        <div class="panel-body" style="padding:0px;">
                                            <div class="col-md-8 sep parent">
                                                <h2 class="conversation-questions" [title]="item['inputMessage']">
                                                    {{item['inputMessage'] | truncate:['60']}}
                                                </h2>
                                                <p class="conversation-response" [title]="item['outputMessage']" [ngStyle]="{ width: (item.attachment) ? '60%' : '100%' }">
                                                    <span>{{item['outputMessage'] | truncate:['280']}}</span>
                                                    <img *ngIf="item['hasattachment']" [src]="item.attachment" alt=""/>
                                                </p>
                                                <div class="absolute">
                                                    <div class="conversation-div" *ngIf="item['contenttype']">
                                                        <i class="icon-green" [ngClass]="applyTypeIcon(item['contenttype'])"
                                                            aria-hidden="true"></i>
                                                    </div>

													 <div class="conversation-div" *ngIf="item['connectionType']">
                            <i class="icon-green" [ngClass]="applyFontIcon(item['connectionType'])" aria-hidden="true"
                                *ngIf="item['connectionType']!=='bbm'"></i>
								<i  [ngClass]="applyFontIcon(item['connectionType'])" aria-hidden="true"
                                *ngIf="item['connectionType']==='bbm'"></i>
                            <span style="text-transform: capitalize;">
								{{item['connectionType'] === 'whatsappofficial' ? 'whatsapp' : item['connectionType']}}
							</span>
                        </div>
                                                    <div class="conversation-div">
                                                        <i class="fa fa-check-circle icon-green" aria-hidden="true"
                                                            *ngIf="item.status=='OK'"></i>
                                                        <i class="fa fa-times-circle icon-red" aria-hidden="true" *ngIf="item.status!='OK'"></i>
                                                        <span i18n="@@status">Status</span>
                                                    </div>
                                                    <div class="conversation-div" *ngIf="item['apiStat']">
                                                        <i class="fa fa-check-circle icon-green" aria-hidden="true"
                                                            *ngIf="item['apiStat'] === '' || item['apiStat'] === 'OK'"></i>
                                                        <i class="fa fa-times-circle icon-red" aria-hidden="true" *ngIf="item['apiStat'] !== '' && item['apiStat'] !== 'OK'"></i>
                                                        <span>Api</span>
                                                    </div>
                                                    <div class="conversation-div" *ngIf="item['manufacture'] && item['manufacture'] !== ''">
                                                        <i class="fas fa-bookmark icon-green" aria-hidden="true"></i>
                                                        <span style="text-transform:capitalize">{{item['manufacture']}}</span>
                                                    </div>
                                                    <div class="conversation-div" *ngIf="item['deviceinfo'] !== ''">
                                                        <i class="fas fa-cube icon-green" aria-hidden="true"></i>
                                                        <span>{{item['deviceinfo']}}</span>
                                                    </div>

                                                    <div class="conversation-div" style="float:right;line-height: 30px;">
                                                        <span class="coversation-date-span">{{item['modifiedDate'] |
                                                            date:"dd/MM/yyyy h:mm a"}}</span>
                                                    </div>
                                                    <div class="conversation-div">
                                                        <i class="fa fa-user icon-green" aria-hidden="true" *ngIf="item['userName'] != ''"></i>
                                                        <span style="padding-right: 5px;" *ngIf="item['userName'] !== ''">
                                                            {{item['userName'] | truncate:['15']}},
                                                        </span>
                                                        <span *ngIf="item['userName'] != ''">
                                                            Room_No {{item['roomNo']}}
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-2 sep cursor-pointer">
                                                <span *ngIf="item['intent']!== ''">#{{item['intent']}}</span>
                                                <span *ngIf="item['intent']=== ''" i18n="@@list_nil">Nil</span>
                                            </div>
                                            <div class="col-md-2 sep cursor-pointer">
                                                <p style="margin-bottom: 5px;" *ngFor="let entity of item['entity']">
                                                    <span *ngIf="entity!==''">
                                                        @{{entity['entity_name']}}: {{entity['entity_value']}}
                                                    </span>
                                                </p>
                                                <p style="margin-bottom: 5px;" *ngIf="item['entity'] == '';" i18n="@@list_nil">
                                                    Nil
                                                </p>
                                            </div>

                                        </div>
                                    </app-panel>
                                </div>
                            </li>
                        </ul>

                    </li>
                </ul>
            </div>
        </div>
    </div>
    <app-pagination [collectionSize]="totalCount" [(page)]="page" [maxSize]="3" [disabled]="paginationDisabled"
        (pageChange)="pageChanged($event)"></app-pagination>
</app-page-header>
