import { Component, Injector, OnInit } from '@angular/core';
import { BroadcastmessageService } from '../broadcastmessage/broadcastmessage.service';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { SharedsService } from 'src/app/shareds/shareds.service';
import { TemplateService } from '../templates/template.service';
import moment from 'moment';

@Component({
  selector: 'app-flex-broadcast',
  templateUrl: './flex-broadcast.component.html',
  styleUrls: ['./flex-broadcast.component.scss']
})
export class FlexBroadcastComponent implements OnInit {
  loaderShow: boolean;
  flexmessages = [];
  user_id: any;
  user_name: any;
  contactCount: any = 0;
  translatorLanguage: any;
  translate: TranslateService;
  toastr: ToastrService;
  sharedService: SharedsService;
  broadcastMessageService: BroadcastmessageService;
  config = {
    displayKey: 'flexName',
    search: true,
    height: 'auto',
    placeholder: 'Select',
    limitTo: this.flexmessages.length,
    moreText: 'more',
    noResultsFound: 'No results found!',
    searchPlaceholder: 'Search',
    searchOnKey: 'flexName',
  };
  flexMessageModel: any;
  templateContent: any;
  messageOption = 'recipient';
  campaignModel: { campaign_label: string; campaign_name: string; }[];
  recipientModel = '';
  send = '';
  minDate: any = new Date();
  toDate: any = moment();
  fromDate: any = moment().subtract(30, 'days');
  bsInlineValue = new Date();
  startDate: any;
  endDate: any;
  timeHH: any = '';
  timeMM: any = '';
  hour: any = '';
  model3: any = {
    power: '24 days'
  };
  broadcast_date2: any = '';
  broadcast_date: any = '';
  broadcast_time: any = '';
  constructor(private injector: Injector, private templateService: TemplateService,) {
    this.broadcastMessageService = this.injector.get<BroadcastmessageService>(
      BroadcastmessageService
    );
    this.translate = this.injector.get<TranslateService>(TranslateService);
    this.toastr = this.injector.get<ToastrService>(ToastrService);
    this.sharedService = this.injector.get<SharedsService>(SharedsService);
    this.translatorLanguage = localStorage.getItem('Language');
    this.translate.setDefaultLang('en');
    this.translate.use(
      this.translatorLanguage ? this.translatorLanguage : 'en'
    );
  }
  ngOnInit() {
    this.user_id = JSON.parse(localStorage.getItem('user'))?.user_id ?? '';
    this.user_name = JSON.parse(localStorage.getItem('user'))?.user_name ?? '';
    const arg = {
      limit: 1000,
      skip: 0,
      search: '',
    };
    this.fetchFlexMessages(arg);
  }
  fetchFlexMessages(args) {
    this.loaderShow = true;
    this.templateService.fetchFlexMessageList(args).subscribe((data) => {
      if (data?.statusCode === 200) {
        this.loaderShow = false;
        this.flexmessages = data?.data ?? [];
      } else {
        this.loaderShow = false;
        this.flexmessages = [];
      }
    })
  }
  chooseFlexSelectionChanged() {
    this.templateContent = this.flexMessageModel['flexJson'];
    try {
      this.templateContent = JSON.stringify(this.templateContent, null, 4); // 4-space indentation
    } catch (e) {
      this.templateContent = JSON.parse(this.templateContent);
    }
  }
  messageOptionChange(e) {
    if (e.value === 'recipient') {
      this.campaignModel = [
        { campaign_label: 'Select', campaign_name: 'Select' },
      ];
    }
  }
  selectBroadcast() {
    if (this.flexMessageModel?.length === 0
    ) {
      this.toastr.error('', this.translate.instant('toastr.selectTemplate'));
      return;
    }

    if (
      this.messageOption === 'messageList' &&
      !this.campaignModel
    ) {
      this.toastr.error('', this.translate.instant('toastr.messagingList'));
      return;
    }
    if (this.messageOption === 'recipient') {
      if (
        this.recipientModel === '' ||
        this.recipientModel === null ||
        this.recipientModel === undefined
      ) {
        this.toastr.error('', this.translate.instant('toastr.recipientNumber'));
        return;
      }
    }
  }
  broadcastFlexMessage() {
    if (this.send === 'now') {
      this.sendFlexBroadcastNow();
    }
    if (this.send === 'schedule') {
      if (this.timeHH === '' || this.timeMM === '') {
        this.toastr.error('', this.translate.instant('toastr.add_time'));
      } else {
        this.dateFix();
        this.sendScheduleBroadcast();
      }
    }
  }

  sendFlexBroadcastNow() {
    this.loaderShow = true;
    const arg = {
      appId: this.sharedService.appId,
      brodcasterId: this.user_id,
      brodcasterName : this.user_name,
      flexName: this.flexMessageModel['flexName'],
      flexMessageId: this.flexMessageModel['_id'],
      receiverAddress: this.recipientModel,
      broadcast_type: 'Now',
    };
    this.broadcastMessageService.flexBroadcastMessage(arg).subscribe(
      (response2) => {
        if (response2.status === 200) {
          this.toastr.info('', this.translate.instant('toastr.messageSent'));
          this.resetData();
        } else if (response2.status === 1013) {
          this.toastr.error('', response2.info);
        } else {
          this.toastr.error('', this.translate.instant('toastr.something_went_wrong'));
        }
        this.loaderShow = false;
      },
      (err7) => {
        this.loaderShow = false;
        this.toastr.error('', this.translate.instant('toastr.something_went_wrong'));
      }
    );
  }
  dateFix() {
    this.broadcast_date = moment(this.bsInlineValue).format('DD-MM-YY');
    this.broadcast_date2 = moment(this.bsInlineValue).format('YYYY-MM-DD');
    this.hour =
      this.broadcast_date2 + 'T' + this.timeHH + ':' + this.timeMM + ':' + '';
    const xyz = new Date(this.hour);
    this.broadcast_time = moment(xyz).format('HH:mm');
  }
  sendScheduleBroadcast() {
    this.loaderShow = true;
    const arg = {
      appId: this.sharedService.appId,
      brodcasterId: this.user_id,
      brodcasterName: this.user_name,
      flexName: this.flexMessageModel['flexName'],
      flexMessageId: this.flexMessageModel['_id'],
      receiverAddress: this.recipientModel,
      broadcastType: 'schedule',
      scheduledDate: moment(this.hour, 'YYYY-MM-DDTHH:mm')
        .seconds(0)
        .milliseconds(0)
        .utc()
        .format(),
    };
    this.broadcastMessageService.flexBroadcastMessage(arg).subscribe(
      (response3) => {
        if (response3.status === 200) {
          this.toastr.info('', this.translate.instant('toastr.messageScheduled'));
          this.loaderShow = false;
          this.resetData();
        } else if (response3.status === 400) {
          this.toastr.error('', response3.info || response3.error.info);
          this.loaderShow = false;
        } else if (response3.status === 1013) {
          this.toastr.error('', response3.info);
          this.loaderShow = false;
        } else {
          this.toastr.error('', this.translate.instant('common.something_went_wrong'));
        }
        this.loaderShow = false;
      },
      (error) => {
        this.toastr.error('', this.translate.instant('common.something_went_wrong'));
      }
    );
  }
  resetData() {
    this.templateContent = '';
    this.recipientModel = null;
    this.send = '';
    this.messageOption = null;
    this.campaignModel = [];
    this.flexMessageModel = null;
  }
}
