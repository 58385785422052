import { Component, OnDestroy, Input, AfterViewInit, OnChanges, SimpleChanges } from '@angular/core';
import { AmChart, AmChartsService } from '@amcharts/amcharts3-angular';

@Component({
  selector: 'app-pie-chart',
  templateUrl: './pie-chart.component.html',
  styleUrls: ['./pie-chart.component.scss']
})
export class PieChartComponent implements OnDestroy, AfterViewInit, OnChanges {
  chart: AmChart;
  viewInit = false;
  @Input() data;
  @Input() graphHeight: any = 265;
  id: any;
  csvFile: any;
  itemsArray = [
    ['a', 'b'], ['1', '2']
    // { itemId: 1, itemRef: "Item 001" },
    // { itemId: 2, itemRef: "Item 002" },
    // { itemId: 3, itemRef: "Item 003" }
  ];
  constructor(private AmCharts: AmChartsService) {
    const crypto = window.crypto;
    const arrays = new Uint32Array(1);
   this.id = crypto.getRandomValues(arrays).toString().substring(4);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['data'] && this.viewInit) {
      this.makeChart();
    }
  }

  ngAfterViewInit() {
    this.makeChart();
    this.viewInit = true;
  }

  makeChart() {
    this.chart = this.AmCharts.makeChart(this.id, {
      'type': 'pie',
      'theme': 'light',
      'dataProvider': this.data?.graph ? this.data.graph : [],
      'valueField': this.data.value,
      'titleField': this.data.title,
      'titles': [
        {
          'text': this.data.header,
          'size': 15
        }
      ],
      'balloon': {
        'fixedPosition': true
      },
      'export': {
        'enabled': true,
        'fileName': this.data.fileName,
        'menu': [{
          'class': 'export-main',
          'menu': [{
            'label': 'Download',
            'menu': ['PNG', 'JPG', 'SVG', 'PDF', 'CSV']
          }],
        }],
        "pageOrigin": false,
      },
      'backgroundColor': '#f7f7f7',
      'colors': ['#1E87F0', ' #F8C239', '#ff6600', '#33cc00', '#113344', '#445500', '#00bb66'
        , '#884411', '#808080', '#55ccff', '#33ff00', '#ff00aa'
      ],
      'responsive': {
        'enabled': true
      },
      'creditsPosition': 'bottom-left',
      'labelsEnabled': false,
      'legend': {
        'position': 'right',
        'autoMargins': false,
        'useMarkerColorForLabels': true,
        'useMarkerColorForValues': true,
        'labelText': '[[title]]',
        'valueText': '[[percents]]% ([[value]])',
        'valueWidth': 100,
        'truncateLabels': 12 // custom parameter,
      },
      'outlineColor': '',
      'startDuration': 0,
      'pathToImages': '/assets/amcharts_3.21.15.free/amcharts/images/',
    });
  }

  ngOnDestroy() {
    if (this.chart) {
      this.AmCharts.destroyChart(this.chart);
    }
  }

}
