import { Component, Injector, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { DeviceDetectorService } from 'ngx-device-detector';
import { LoaderService } from 'src/app/loader/loader.service';
import { environment } from 'src/environments/environment';
import { LoginService } from '../login/login.service';
import { ValidateEmail } from './emailHelper';
import { ValidatePassword } from './password';
import { RegisterService } from './register.service';
import * as uuid from 'uuid';
import { SharedsService } from 'src/app/shareds/shareds.service';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {
  loginForm: UntypedFormGroup;
  signupForm: UntypedFormGroup;
  submitted = false;
  error: any;
  signuperror: any;
  bizId: string = null;
  bizName: string = null;
  bizLogo: string = null;
  existingAccounts: any = [];
  selectedAccountToConnect: any = null;
  browser_details: any = null;
  redirectUrl: string;
  hasAccesstoMultipleApps = false;
  isLoggedIn = false;
  projectId: string = null;
  validUser = true;
  appid: string;
  username: string;
  password: string;
  normalRegister = false;
  reqForm = 'web';
  formBuilder: UntypedFormBuilder;
  currentRoute: ActivatedRoute;
  router: Router;
  registerService: RegisterService;
  deviceService: DeviceDetectorService;
  sharedService: SharedsService;
  loginService: LoginService;
  loader: LoaderService;
  translate: TranslateService;
  translatorLanguage: any;
  selectedABCOption = 'abcUE';
  abcCouponCode = '';
  addProjectUESuccess = false;
  constructor(private injector: Injector) {
    this.formBuilder = this.injector.get<UntypedFormBuilder>(UntypedFormBuilder);
    this.currentRoute = this.injector.get<ActivatedRoute>(ActivatedRoute);
    this.router = this.injector.get<Router>(Router);
    this.registerService = this.injector.get<RegisterService>(RegisterService);
    this.deviceService = this.injector.get<DeviceDetectorService>(DeviceDetectorService);
    this.sharedService = this.injector.get<SharedsService>(SharedsService);
    this.loginService = this.injector.get<LoginService>(LoginService);
    this.loader = this.injector.get<LoaderService>(LoaderService);
    this.translate = this.injector.get<TranslateService>(TranslateService);
    this.translatorLanguage = localStorage.getItem('Language');
    this.translate.setDefaultLang('en');
    this.translate.use(this.translatorLanguage ? this.translatorLanguage : 'en');
   }

  get f() { return this.loginForm.controls; }
  get s() { return this.signupForm.controls; }

  ngOnInit() {
    this.bizId = this.currentRoute.snapshot.queryParamMap.get('id') ? this.currentRoute.snapshot.queryParamMap.get('id') : '';
    this.bizName = this.currentRoute.snapshot.queryParamMap.get('name') ? this.currentRoute.snapshot.queryParamMap.get('name') : '';
    this.bizLogo = this.currentRoute.snapshot.queryParamMap.get('logo') ? this.currentRoute.snapshot.queryParamMap.get('logo') : '';
    this.setBrowserDetails();
    localStorage.clear();

    this.loginForm = this.formBuilder.group({
      username: ['', [Validators.required]],
      password: ['', Validators.required]
    });

    this.signupForm = this.formBuilder.group({
      email: ['', Validators.required],
      password: ['', Validators.required],
      confirmpassword: ['', Validators.required],
      name: ['', Validators.required]
    }, {
        validator: [ValidatePassword.MatchPassword, ValidateEmail.emailValidator]
    });

    if (!this.bizId || !this.bizName) {
      return this.router.navigateByUrl('/login');
    } else {
      this.normalRegister = false;
      this.reqForm = 'apple';
    }
  }

  setBrowserDetails() {
    this.browser_details = this.deviceService.getDeviceInfo();
  }

  setValues(value1, values2) {
    if (value1) {
      return value1;
    } else {
      return values2;
    }
  }
  onSubmitUE() {
    this.submitted = true;
    if (!this.abcCouponCode && this.selectedABCOption === 'abcUE') {
      return;
    } else {
      this.loader.display(true);
    }
    const args = {
      bizId: this.bizId,
      bizName: this.bizName,
      bizLogo: this.bizLogo,
      couponCode: this.abcCouponCode.trim(),
    };
    this.registerService.addProjectUE(args).subscribe(data => {
      if (data && !data.status) {
        this.loader.display(false);
        this.validUser = true;
        this.signuperror = null;
        this.error = this.setValues(data.info, 'Sorry, Something went wrong.');
      } else if (data.status === 400) {
        this.loader.display(false);
        this.validUser = true;
        this.signuperror = null;
        this.error = this.setValues('', 'Sorry, Something went wrong.');
      } else {
        this.addProjectUESuccess = true;
        this.loader.display(false);
      }
    }, error => {
      this.loader.display(false);
      this.error = this.setValues('', 'Sorry, Something went wrong.');
    });
  }
  onSubmit() {
    this.submitted = true;
    if (this.loginForm.invalid) {
      return;
    } else {
      this.loader.display(true);
    }

    const args = {
      bizId: this.bizId,
      bizName: this.bizName,
      bizLogo: this.bizLogo,
      username: this.f.username.value.trim(),
      password: this.f.password.value.trim(),
      browserDetails: this.browser_details,
    };
    this.registerService.login(args).subscribe(data => {
      if (data && !data.success) {
        this.loader.display(false);
        this.validUser = true;
        this.signuperror = null;
        this.error = this.setValues(data.message, 'Sorry, Something went wrong.');
      } else {
        this.projectId = data.user_data?.appSettings?.projectDetail?._id ? data.user_data.appSettings.projectDetail._id : '';
        this.username = this.f.username.value;
        this.password = this.f.password.value;
        if (this.normalRegister) {
          this.existingAccounts = [{ 'name': data.user_data.companyName, 'detail': this.projectId }];
        } else {
          this.existingAccounts = [{ 'name': this.bizName, 'detail': this.bizId }];
        }
        this.loader.display(false);
      }
    });
  }

  showSignUpForm() {
    this.validUser = false;
    this.loginForm.reset();
    this.error = null;
    this.loader.display(false);
    this.submitted = false;
 }

  generateAppId() {
    return uuid.v4();
  }

  onSubmitSignUp() {
    this.submitted = true;
    if (this.signupForm.invalid && (this.signupForm.value.password === this.signupForm.value.confirmpassword)) {
      return;
    } else {
      this.loader.display(true);
    }
    this.appid = this.generateAppId();
    const args = {
      'app_name': this.bizName ? this.bizName : ('test' + this.appid),
      'appId': this.appid,
      'app_key': 'test_key',
      'app_secret': 'test_secret',
      'reqFrom':  this.reqForm
    };

    this.registerService.addNewProject(args).subscribe(data => {
      if (!data.status) {
        this.loader.display(false);
      } else {
        this.validUser = true;
        this.projectId = data.projectId;
        this.addMenuTabs();
      }
    }, error => {
      this.loader.display(false);
    });
  }

  addMenuTabs() {
    const args = {
    'projectid': this.projectId,
    'appid': this.appid,
    'user_type' : 'master',
    'menu_tab': [{'font_icon': 'fas fa-th', 'sub': null, 'status': true, 'icon': 'overview.png', 'path': '/overviews', 'name': 'Dashboard'},
{'sub': [{'font_icon': '', 'sub': null, 'status': true, 'icon': 'overview.png', 'path': '/Conversations', 'name': 'Live Conversation'},
{'font_icon': '', 'sub': null, 'status': true, 'icon': 'overview.png', 'path': '/history', 'name': 'History'}],
'font_icon': 'far fa-comment-alt', 'status': true, 'icon': 'conversations.png', 'path': '/Conversations', 'name': 'Conversations'},
{'sub': [{'font_icon': '', 'sub': null, 'status': true, 'icon': 'overview.png', 'path': '/ConversationalElements', 'name': 'Conversational Elements'}
], 'font_icon': 'far fa-images', 'status': true, 'icon': 'overview.png', 'path': '/ConversationalElements', 'name': 'Content Elements'}
      // ,{ "font_icon": "fas fa-chart-bar", "sub": null, "status": true, "icon": "analytics.png", "path": "/Analytics", "name": "Analytics" }
    ]
    };

    this.registerService.addMenuTabs(args).subscribe(data => {
      if (!data.success) {
        this.loader.display(false);
      } else {
        this.signupUser();
      }
    }, error => {
      this.loader.display(false);
    });
  }

  signupUser() {
    const args = {
      email: this.signupForm.value.email.trim(),
      username: this.signupForm.value.email.trim(),
      phone_no: '',
      password: this.signupForm.value.password.trim(),
      user_type: 'master',
      name: this.signupForm.value.name,
      timezone: 'Asia/Singapore',
      projectid: this.projectId,
      reqFrom: this.reqForm,
      appid: this.appid,
      lang: localStorage.getItem('Language') || 'en'
    };

    this.registerService.signupUser(args).subscribe(data => {
      if (data.status === 500) {
        this.loader.display(false);
        if (data.message) {
          this.signuperror = data.message;
        } else if (data.info) {
          this.signuperror = data.info;
        } else {this.signuperror = 'Something went wrong.'; }
      } else if (this.bizName && this.bizId) {
          this.existingAccounts = [{ 'name': this.bizName, 'detail': this.bizId }];
          this.username = this.s.email.value.trim();
          this.password = this.s.password.value.trim();
          this.loader.display(false);
          this.signuperror = '';
        } else {
          this.username = this.s.email.value.trim();
          this.password = this.s.password.value.trim();
          this.signup();
          this.loader.display(false);
          this.normalRegister = true;
          this.signuperror = '';
        }
    }, error => {
      this.loader.display(false);
    });
  }

  cancel() {
    this.loginForm.reset();
    this.signupForm.reset();
    this.existingAccounts = [];
    this.submitted = false;
    this.error = '';
    this.selectedAccountToConnect = null;
    this.validUser = true;
    this.isLoggedIn = false;
  }

  reset() {
    this.submitted = false;
    this.error = '';
    this.loginForm.reset();
    this.signupForm.reset();
    this.appid = null;
    this.bizId = null;
    this.bizName = null;
    this.bizLogo = null;
    this.selectedAccountToConnect = null;
    this.existingAccounts = [];
    this.username = null;
    this.password = null;
    this.validUser = true;
  }

  connect(index) {
    this.loader.display(true);
    if (this.existingAccounts.length === 1) {
      this.selectedAccountToConnect = this.existingAccounts[0];
    } else if (this.existingAccounts.length > 1) {
      this.selectedAccountToConnect = this.existingAccounts[index];
    } else {
      this.selectedAccountToConnect = null;
    }

    if (this.bizId && this.bizName) {
      const args = {
        'bizId': this.bizId,
        'bizName': this.bizName,
        'bizLogo': this.bizLogo,
        'projectId': this.projectId
      };

      this.registerService.proceedToSignUp(args).subscribe(data => {
        if (!data.status) {
          this.loader.display(false);
        } else {
          this.isLoggedIn = true;
          this.existingAccounts = [];
          this.loader.display(false);
        }
      }, error => {
        this.loader.display(false);
      });
    } else {
      this.signup();
      this.existingAccounts = [];
      this.loader.display(false);
    }
  }

  signup() {
    const args = {
      bizId: this.bizId,
      bizName: this.bizName,
      bizLogo: this.bizLogo,
      username: this.username,
      password: this.password,
      browserDetails: this.browser_details
    };
    this.registerService.login(args).subscribe(data => {
      if (data && !data.success) {
        this.loader.display(false);
        this.error = data.message ? data.message : 'Sorry, Something went wrong.';
        this.reset();
        this.isLoggedIn = false;
      } else {
        this.handleRegisterLogin(data);
      }
    }, error => {
      this.reset();
      this.isLoggedIn = false;
      this.loader.display(false);
    });
  }
  handleRegisterLogin(data) {
    this.loader.display(false);
    this.reset();
    const allowedAppIds = data.user_data.appid;
    const userData = data?.user_data ?? {};
    if (userData.appid.length > 0) {
      userData.appid = userData.appid[0];
    }
    localStorage.setItem('user', JSON.stringify(userData));
    localStorage.setItem('allowedAppIds', allowedAppIds);
    if (userData.companyNameLogoUrl) {
      localStorage.setItem('companyNameLogoUrl', JSON.stringify(userData.companyNameLogoUrl));
    }
    if (userData.companyName) {
      localStorage.setItem('companyName', JSON.stringify(userData.companyName));
    }

    localStorage.setItem('access-token', JSON.stringify(userData.token));
    this.sharedService.appId = userData.appid ? userData.appid : '';
    this.sharedService.token = userData.token ? userData.token : '';
    if (userData.companyNameLogoUrl) {
      localStorage.setItem('companyNameLogoUrl', JSON.stringify(userData.companyNameLogoUrl));
    }
    if (userData.companyName) {
      localStorage.setItem('companyName', JSON.stringify(userData.companyName));
    }

    if (userData.name) {
      localStorage.setItem('name', JSON.stringify(userData.name));
    }

    this.redirectUrl = this.loginService.redirectUrl ? this.loginService.redirectUrl : environment.dashboardPrefix + data.user_data.roles.menu_tab[0].path;
    this.hasAccesstoMultipleApps = userData.multiAppLogin;
    this.isLoggedIn = true;
  }
  appleBusinessRegister() {
    window.open('https://register.apple.com/' , '_blank', 'noopener, noreferrer');
  }

  continue() {
    if (this.hasAccesstoMultipleApps) {
      this.router.navigate(['apps']);
    } else {
      this.router.navigate([this.redirectUrl]);
    }
  }
  selectedABCChange(value) {
    this.selectedABCOption = value;
    this.error = '';
    this.submitted = false;
  }
}
