import { Component, OnInit, ViewChild, OnDestroy, Injector } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { UntypedFormControl } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { MsgCenterService } from './msg-center.service';
import { LoginService } from '../../../login/login.service';
import { ToastrService, ToastContainerDirective } from 'ngx-toastr';
import { HomeService } from '../../home.service';
import { SocketService } from '../../socket.service';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { WebNotificationService } from '../../../../web-notification.service';
import * as _ from 'lodash';
import { environment } from '../../../../../environments/environment';
import { Subscription } from 'rxjs';
import { SharedsService } from 'src/app/shareds/shareds.service';
import { ConversationService } from 'src/app/dashboard/home/conversation/conversation.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-msg-center',
  templateUrl: './msg-center.component.html',
  styleUrls: ['./msg-center.component.scss']
})
export class MsgCenterComponent implements OnInit, OnDestroy {
  @ViewChild(ToastContainerDirective) toastContainer: ToastContainerDirective;
  dateFormat: any;
  search: UntypedFormControl = new UntypedFormControl();
  searchQA: UntypedFormControl = new UntypedFormControl();
  dashboardPrefix;
  bellEngagestatus: any;
  engagerID: any;
  groupAgent = false;
  user_id: string;
  new_user_group_routing: string;
  userName = null;
  userType = null;
  count = null;
  notifications = null;
  resultList: any = [];
  total = null;
  currentRoute: string;
  routeSubscripion: Subscription;
  pwdBlock = false;
  closeButton = false;
  appName: any;
  appAgentHandover = false;
  disengageWindows: any;
  screenWidth: number;
  bellmessage: any;
  bellMessageSubsciption: Subscription;
  newUserRoutingSubscription: Subscription;
  agentDeletedSubscription: Subscription;
  randomAgentProject: boolean;
  routingAgentProject: boolean;
  openChatBoxMessages = [];
  agentDetails = []; // used to store user_id and agent_id of engaged users.
  disable_engage_click = false;
  // Scrolling pagination
  pagination = {
    noOfPages: 0,
    currentPage: 1,
    pageSize: 10
  };
  notificationSpinner = false;
  headerNotifications = [];
  sideBarWidth = '270px';
  subscription: Subscription;
  router: Router;
  spinner: NgxSpinnerService;
  webNotification: WebNotificationService;
  msgCenterService: MsgCenterService;
  loginService: LoginService;
  toastr: ToastrService;
  homeService: HomeService;
  socket: SocketService;
  conversationService: ConversationService;
  sharedService: SharedsService;
  userAppNames: any;
  showSettings = true;
  notificationData: any;
  translatorLanguage: string;
  translate: TranslateService;
  userData: any;
  closeNotify = false;


  constructor(private injector: Injector) {
    this.router = this.injector.get<Router>(Router);
    this.spinner = this.injector.get<NgxSpinnerService>(NgxSpinnerService);
    this.webNotification = this.injector.get<WebNotificationService>(WebNotificationService);
    this.msgCenterService = this.injector.get<MsgCenterService>(MsgCenterService);
    this.loginService = this.injector.get<LoginService>(LoginService);
    this.toastr = this.injector.get<ToastrService>(ToastrService);
    this.homeService = this.injector.get<HomeService>(HomeService);
    this.socket = this.injector.get<SocketService>(SocketService);
    this.conversationService = this.injector.get<ConversationService>(ConversationService);
    this.sharedService = this.injector.get<SharedsService>(SharedsService);
    this.translate = this.injector.get<TranslateService>(TranslateService);
    this.translatorLanguage = localStorage.getItem('Language');
    this.translate.setDefaultLang('en');
    this.translate.use(this.translatorLanguage ? this.translatorLanguage : 'en');
    this.subscription = this.homeService.getMessage().subscribe((data) => {
      if (data.type === 'sideBar') {
        this.sideBarWidth = data.message ? '270px' : '70px';
      }
    });
    this.sharedService.onMainEvent.subscribe(
      (name) => {
        this.userName = name;
      }
   );
  }

  ngOnInit() {
    window.onstorage = (event) => {
      const userData = JSON.parse(localStorage.getItem('user'));
      const checkAppId = userData?.appid ?? '';
      const sessionAppid = sessionStorage.getItem('appid');
      if (checkAppId !== sessionAppid) {
        sessionStorage.setItem('tabClose', 'close');
        this.loginService.logout().subscribe(response => {
          this.router.navigate([environment.dashboardPrefix + '/login']);
        });
      } else {
        sessionStorage.setItem('tabClose', 'open');
      }
    };
    setTimeout(() => {
      this.count = 0;
    }, 0);
    this.userData = JSON.parse(localStorage.getItem('user')) ? JSON.parse(localStorage.getItem('user')) : {};
    const date = this.userData?.dateFormat ?? 'MM/dd/YYYY';
    this.dateFormat = date + ' h:mm a';
    const appNames = localStorage.getItem('allowedAppIds') ? localStorage.getItem('allowedAppIds') : '';
    this.userAppNames = appNames.split(',');
    this.getAppNamesApi();
    this.socket.getOnlineAgents().subscribe(message => {});
    this.fetchDetails();
    this.homeService.getsendChatBoxMessages().subscribe(res => {
      this.openChatBoxMessages = res.message;
    });

    this.routeSubscripion = this.router.events.subscribe((e: any) => {
      // If it is a NavigationEnd event re-initalise the component
      if (e instanceof NavigationEnd) {
        this.currentRoute = this.router.url;
      }
    });
    this.getbellMessages();
    this.newUserRoutingSubscription = this.socket.getNewUserRoutingUpdate().subscribe(agentList => {
      this.agentRoutingAlert(agentList);
    }, error => {
    });

    this.spinner.show();
    this.initializeResultList();
    this.socket.getNotification().subscribe(notification => {
      this.getNotification(notification);
    });

    this.toastr.overlayContainer = this.toastContainer;

    // Searching
    this.search.valueChanges.pipe(debounceTime(500)).pipe(distinctUntilChanged()).subscribe(result => {
      this.homeService.sendMessage({
        type: 'search',
        message: result
      });
    });

    this.searchQA.valueChanges.pipe(debounceTime(500)).pipe(distinctUntilChanged()).subscribe(result => {
      this.homeService.sendMessage({
        type: 'searchQA',
        message: result
      });
    });

    // Reset search value when route changes
    this.router.events.subscribe((event) => {
      this.searchQA.reset();
      this.search.reset();
    });
    this.screenWidth = window.innerWidth;
    this.getAgentApiOnload();
    this.msgCenterService.getMsgcenterReloadState().subscribe(state => {
      this.ngOnInit();
    });
  }
  getAgentApiOnload() {
    this.homeService.nlpAgentState().subscribe(message => {
      this.resultList = this.resultList.map(notification => {
        if (notification.userId === message.message.id) {
          notification.agent_id = [];
          notification.new_user = false;
        }
        return notification;
      });
    });
    this.agentDeletedSubscription = this.socket.getAgentStatusUpdate().subscribe(agent => {
      if (agent?.data?.status === 'inactive' && this.userData.user_id === agent.data.agentId) {
        // this.loginService.logout().subscribe(response => {
        localStorage.clear();
        this.socket.disconnect();
        this.router.navigate([environment.dashboardPrefix + '/login']);
        // });
      }
    }, error => {});
  }
  getbellMessages() {
    this.bellMessageSubsciption = this.homeService.getbellMessage().subscribe(data => {
      if (data.type === 'bellMessage' && data.message) {
        this.bellmessage = this.resultList.filter(item =>
          item.category === 'engageUser' && item.userId === data.message.id
        );
        if (this.bellmessage.length) {
          this.bellmessage[0].isbellMsg = true;
          this.notificationEngage(this.bellmessage[0], undefined, null);
        }
      }
    }, error => {    });
  }
  fetchDetails() {
    const userdetails = JSON.parse(localStorage.getItem('user'));
    if (userdetails) {
      this.user_id = userdetails.user_id ? userdetails.user_id : '';
      this.groupAgent = userdetails.groupAgent;
      this.new_user_group_routing = userdetails.appSettings.projectDetail.new_user_group_routing;
      this.randomAgentProject = userdetails.appSettings.projectDetail.randomAgent ? userdetails.appSettings.projectDetail.randomAgent : false;
      this.routingAgentProject = userdetails.appSettings.projectDetail.routingAgent ? userdetails.appSettings.projectDetail.routingAgent : false;
      this.appName = userdetails.appname ? userdetails.appname : '';
      this.userType = userdetails.user_type ? userdetails.user_type : '';
    }
    this.disengageWindows = document.getElementsByName('disengageWindows');
    this.userName = JSON.parse(localStorage.getItem('name'));
    this.appAgentHandover = this.userData.appSettings.conversations.agent_handover ? this.userData.appSettings.conversations.agent_handover : false;
    this.dashboardPrefix = environment.dashboardPrefix === '/' ? '' : environment.dashboardPrefix;
    this.currentRoute = this.router.url;
  }
  getAppNamesApi() {
    const args = {
      userappnames: []
    };
    args.userappnames = this.userAppNames;
    this.msgCenterService.getAppNames(args).subscribe((result => {
      if (result) {
        let userApps = result.info ? result.info : [];
        userApps = userApps.filter(app => {
          return (app.appid === this.sharedService.appId);
        });

        this.showSettings = userApps[0].settings;
      }
    }));
  }
  agentRoutingAlert(newUserRoutingAgentList) {
    let user;
    if (newUserRoutingAgentList.group_count > 0) {
      let index = -1;
      index = newUserRoutingAgentList.data.findIndex(item => item.agentId === this.user_id);
      if (index >= 0) {
        user = JSON.parse(localStorage.getItem('user'));
        user.groupAgent = true;
        localStorage.setItem('user', JSON.stringify(user));
      } else {
        user = JSON.parse(localStorage.getItem('user'));
        user.groupAgent = false;
        localStorage.setItem('user', JSON.stringify(user));
      }
    } else {
      user = JSON.parse(localStorage.getItem('user'));
      user.groupAgent = false;
      localStorage.setItem('user', JSON.stringify(user));
    }
  }

  profileclick() {
    this.userName = JSON.parse(localStorage.getItem('name'));
  }

  changePassword() { this.pwdBlock = true; this.closeButton = true; }

  editProfile() {
    this.router.navigateByUrl(this.dashboardPrefix + '/editprofile');
  }
  // tslint:disable-next-line:indent
  closeEvent(event: any) { this.pwdBlock = false; }

  ngOnDestroy(): void {
    // Called once, before the instance is destroyed.
    // Add 'implements OnDestroy' to the class.
    this.routeSubscripion.unsubscribe();
    this.bellMessageSubsciption.unsubscribe();
    this.newUserRoutingSubscription.unsubscribe();
    this.agentDeletedSubscription.unsubscribe();
  }

  applyfontcolor(channel: any): string {
    let returnElement;
    switch (channel) {
      case 'approveQuestion':
        returnElement = '#2AC940';
        break;
      case 'rejectQuestion':
        returnElement = ' red';
        break;
      default:
        returnElement = '#1f1f1f';
        break;
    }
    return returnElement;
  }

  // Logout function
  logout(): void {
    // tslint:disable-next-line:triple-equals
    if (localStorage.getItem('isRemoveFromMessageList') === 'true') {
      if (this.homeService.agentOnlineStatus) {
        this.homeService.agentOnlineStatus.messagelength = 0;
      }
      localStorage.setItem('isRemoveFromMessageList', null);
    }
    if (this.homeService.agentOnlineStatus && this.homeService.agentOnlineStatus.messagelength > 0) {
      this.toastr.error(this.disengageWindows[0].innerText);
      this.router.navigate([environment.dashboardPrefix + '/liveagents']);
    } else {
      const status = {
        'status': 'away'
      };

      this.sharedService.AgentOnlineStatus(status).subscribe(response => {
        // Do Nothing
      });

      this.loginService.logout().subscribe(response => {
        localStorage.clear();
        this.socket.disconnect();
        this.router.navigate([environment.dashboardPrefix + '/login']);
      });
    }
  }

  settingsClick() {
    this.router.navigate([environment.dashboardPrefix + '/settings']);
  }

  usersClick() {
    this.router.navigate([environment.dashboardPrefix + '/users']);
  }

  // Call list notification api
  initializeResultList(args = { limit: 30, skip: 0 }): void {
    this.msgCenterService.listNotifications(args).subscribe(response => {
      if (response) {
        this.spinner.hide();
        this.notificationSpinner = false;
        this.count = response.count;
        this.total = response.totalCount;
        // Sort notification based on date
        const notifications = response?.info ?? [];
        if (notifications?.length) {
          this.notifications = notifications.filter(msg => {
            return msg.modified_on;
          });
        }
        if (this.notifications?.length) {
          this.notifications = this.notifications.sort((a: any, b: any) =>
            new Date(b.modified_on).getTime() - new Date(a.modified_on).getTime()
          );
          this.resultList = this.resultList.concat(this.notifications);
        }
        this.resultList?.forEach((item) => {
          const timeStamp = Math.round(new Date().getTime() / 1000);
          const timeStampYesterday = timeStamp - (24 * 3600);
          const is24 = new Date(item.modified_on).getTime() >= new Date(timeStampYesterday * 1000).getTime();
          item.is24 = is24;
        });
        this.setUserAndAgentId();
        this.resultList = _.uniqBy(this.resultList, function (x: any) {
          return x.userId;
        });

        this.resultList = this.resultList.filter(x => {
          if (x.agent_id.length) {
            return x.agent_id.includes(this.user_id);
          } else {
            return x;
          }
        });
        this.pagination.noOfPages = Math.ceil(this.total / this.pagination.pageSize);
      }
    });

  }
  setUserAndAgentId() {
    this.resultList?.map(result => {
      if (!result.userId) {
        result.userId = result.sender ? result.sender : '';
      }

      const userID = result.userId;
      for (const i of this.agentDetails) {
        if (i.userId === userID) {
          result.agent_id = i.agent_id;
        }
      }
    });
  }
  // More data through pagination
  loadMoreCourses(): void {
    if (this.pagination.currentPage >= this.pagination.noOfPages) {
      this.notificationSpinner = false;
      return;
    }
    this.notificationSpinner = true;
    const args = {
      limit: this.pagination.pageSize,
      skip: this.pagination.currentPage * this.pagination.pageSize
    };
    this.pagination.currentPage++;
    this.initializeResultList(args);
  }

  notificationEngage(notification, event, $event) {
    this.closeNotify = true;
    this.notificationData = notification;
    if ($event?.detail && $event.detail !== 1) {
      event.preventDefault();
      return;
    }

    this.disable_engage_click = true;
    let flag = 0;
    this.openChatBoxMessages.forEach(e => {
      if (e.id === notification.userId) {
        flag = ++flag;
      }
    });

    if (flag > 0) {
      return;
    }

    const agentDetail = {
      userId: '',
      agent_id: ''
    };
    agentDetail.userId = notification.userId;
    agentDetail.agent_id = notification.agent_id;
    this.agentDetails.push(agentDetail);
    const argsRead = { notification_id: notification?.id ?? notification._id };
    this.msgCenterService.readNotification(argsRead).subscribe(response => {
      if (response) {
        this.resultList = [];
        this.initializeResultList();
      }
    });
    if (!event) {
      this.detailsNotificationApi(notification);
    }
  }
  detailsNotificationApi(notification) {
    const argsDetails = {
      category: 'engageUser',
      categoryId: this.setValues(notification.categoryId, notification.category_id),
      id: this.setValues(notification.id, notification._id),
      userId: this.setValues(notification.userId, notification.sender)
    };
    this.msgCenterService.detailsNotification(argsDetails).subscribe(response => {
      if (response?.info?.engage_enabled) {
        this.bellEngagestatus = this.setValues(response.info.engage_enabled.status, '');
        this.engagerID = this.setValues(response.info.engage_enabled.engagerId, null);
        if (this.engagerID == null || this.engagerID) {
          this.bellEngagestatus = this.setValues(response.info.engage_enabled.status, '');
          this.registerPopup(response.info, 0);
        }
      }
    }, err => {
      const error = err?.error?.info ? err.error.info : 'Sorry, something went wrong';
      this.toastr.error('', error);
    });
  }
  registerPopup(conversation, skip) {
    this.conversationService.engagerSetting(this.user_id).subscribe(response => {
      if (response.success === true) {
        const engageArg = {
          engage_enabled: true,
          connectionName: conversation.connectionName ? conversation.connectionName : '',
          engager_id: this.user_id,
          user_id: conversation.id ? conversation.id : '',
          messageId: conversation.msgId ? conversation.msgId : '',
          phone_no: conversation.phoneNo ? conversation.phoneNo : '',
          reassigned_engage_request: conversation.agent_reassigned ? conversation.agent_reassigned : false,
        };
        this.conversationService.engagerChecking(engageArg).subscribe(responseCheck => {
          if (responseCheck.status === false) {
            this.router.navigate([environment.dashboardPrefix + '/liveagents'], { state: { id: this.notificationData } });
            this.homeService.sendNotificationMessage({
              type: 'engage',
              message: conversation,
            });

          }
        }, error => {
        }); }});
  }

  notificationRead(notification): void {
    this.closeNotify = true;
    const argsRead = { notification_id: notification.id };
    this.msgCenterService.readNotification(argsRead).subscribe(response => {
      if (response) {
        if (!this.resultList) {
          this.resultList = [];
        }
        this.initializeResultList();
      }
    });
  }

  countUpdate(): void {
    this.closeNotify = false;
    if (this.count > 0) {
    this.msgCenterService.updateSeenNotification({ count: 0 }).subscribe(() => {
      this.count = 0;
    });
  }
  this.updateDate();
  }

  updateDate() {
    const date = this.userData.dateFormat ? this.userData.dateFormat : 'MM/dd/YYYY';
    this.dateFormat = date + ' h:mm a';
  }
  desktopAlertNotificationNewuser(notification) {
    if (notification.new_user_group_routing === true) {
      if (notification.category === 'engageUser' || notification.category === 'engagedUserMessage') {
        if (this.groupAgent === true) {
          this.webNotification.showNotification(notification);
        }
      }
    }
    if (notification.new_user_group_routing === false) {
      if (notification.category === 'engageUser' || notification.category === 'engagedUserMessage') {
        this.webNotification.showNotification(notification);
      }
    }
  }
  desktopAlertNotification(notification) {
    if (notification.new_user === true) {
      this.desktopAlertNotificationNewuser(notification);
    }
    if (notification.new_user === false) {
      if (notification.category === 'engageUser' || notification.category === 'engagedUserMessage') {
        if (!notification.agent_id.length || notification.agent_id.includes(this.user_id)) {
          this.webNotification.showNotification(notification);
        }
      }
    }
  }
  getNotification(notification) {
    const checkAppId = this.userData.appid ? this.userData.appid : '';
    /*-----------------------------------------------------------------------------------------------------------*/
    if (notification.desktop_alert === true && notification.appid === checkAppId) {
      this.desktopAlertNotification(notification);
    }

    /*-----------------------------------------------------------------------------------------------------------*/
    if (
      ((notification.category !== 'engageMessage' &&
          !notification.receiverId) ||
        (notification?.receiverId?.[0]?.length &&
          notification.receiverId[0] === this.user_id)) &&
      notification.appid &&
      notification.appid === checkAppId
    ) {
      this.engageMessageNotification(notification);
    }
  }
  engageMessageNotification(notification) {
      // count variable now updates only when message contain agent_id = userID or as null.
      if (notification.agent_id && (notification.agent_id.includes(this.user_id) || !notification.agent_id.length)) {
        this.count++;
      }

      this.resultList = this.resultList.filter(
        (item) => item.message !== notification.message
      );
      this.resultList.unshift(notification);
      this.resultList.forEach((item) => {
        const timeStamp = Math.round(new Date().getTime() / 1000);
        const timeStampYesterday = timeStamp - 24 * 3600;
        const is24 =
          new Date(item.modified_on).getTime() >=
          new Date(timeStampYesterday * 1000).getTime();
        item.is24 = is24;
      });

      // mapping for agent id
      this.resultList.map((result) => {
        let userIdValue = '';
        if (result.userId) {
          userIdValue = result.userId;
        } else if (result.sender) {
          userIdValue = result.sender;
        } else {
          // do nothing;
        }
        const userID = userIdValue;
        for (const i of this.agentDetails) {
          if (i.userId === userID) {
            result.agent_id = i.agent_id;
          }
        }
      });

      this.resultList = _.uniqBy(this.resultList, function (x: any) {
        return x.userId;
      });

      this.resultList = this.resultList.filter((x) => {
        if (x.agent_id.length) {
          return x.agent_id.includes(this.user_id);
        } else {
          return x;
        }
      });
  }
  initResponsiveElements() {
    this.screenWidth = window.screen.width;
  }

  checkWindowSize() {
    if (this.screenWidth > 992) {
      return false;
    } else {
      return true;
    }
  }

  firstWord(name) {

    return name.split(' ')[0];
  }

  setValues(val1, val2) {
    if (val1) {
      return val1;
    } else {
      return val2;
    }
  }
}
