import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HomeService } from '../home/home.service';
import { MsgCenterService } from '../home/navbar/msg-center/msg-center.service';
import { environment } from '../../../environments/environment';
import { SharedsService } from 'src/app/shareds/shareds.service';

@Component({
  selector: 'app-appselector',
  templateUrl: './appselector.component.html',
  styleUrls: ['./appselector.component.scss'],
})
export class AppselectorComponent implements OnInit {
  userType: string;
  userId: string;
  userApps = [];
  userAppNames: any;
  appNumberAbove2: boolean;
  loadershow = false;
  channels = [];

  constructor(
    private router: Router,
    private homeService: HomeService,
    private msgCenterService: MsgCenterService,
    private sharedService: SharedsService,
  ) {}

  ngOnInit() {
    this.loadershow = true;
    const user = JSON.parse(localStorage.getItem('user')) ? JSON.parse(localStorage.getItem('user')) : '';
    if (user) {
      this.userType = user.user_type;
      this.channels = user.channels;

      this.userId = user.user_id ? user.user_id : '';
    }
    const appNames = localStorage.getItem('allowedAppIds') ? localStorage.getItem('allowedAppIds') : '';
    this.userAppNames = appNames.split(',');
    const args = {
      userappnames: [],
    };
    args.userappnames = this.userAppNames;
    this.msgCenterService.getAppNames(args).subscribe(
      (result) => {
        this.userApps = result.info.map((app) => {
          if (app.appIcon === '') {
            app.appIcon = '../assets/img/uib-blue.png';
          }
          if (app.appName === 'Wellness Shop') {
            app.appIcon = '../assets/images/wshop.jpg';
          }
          return app;
        });

        this.loadershow = false;
        this.appNumberAbove2 = this.userApps.length > 2;
      },
      (err) => {
        this.loadershow = false;
      }
    );
  }

  onGridClick(app) {
    const args = { appid: '', usertype: '', userid: '' , lang: ''};
    const lang = localStorage.getItem('Language');
    args.appid = app.appid;
    args.usertype = this.userType;
    args.userid = this.userId;
    args.lang = lang;
    this.msgCenterService.getAppDetails(args).subscribe((result) => {
      const local_data = JSON.parse(localStorage.getItem('user'));
      const theme = result.user_data?.['appSettings']?.['projectDetail']?.theme;
      localStorage.setItem('theme', theme || 'black-style.css');
      sessionStorage.setItem('loggedin', 'true');
      if (result?.user_data?.createMenuSubList) {
        local_data.createMenuSubList = result.user_data.createMenuSubList;
      }

      if (result.user_data.roles) {
        local_data.roles.menu_tab = result.user_data.roles.menu_tab;
      }

      if (result.user_data.appid) {
        local_data.roles.appid = result.user_data.appid;
        local_data.appid = result.user_data.appid;
        sessionStorage.setItem('appid', result.user_data.appid);
      }

      if (result.user_data.appname) {
        local_data.roles.appname = result.user_data.appname;
        local_data.appname = result.user_data.appname;
      }
      if (result.user_data.channels) {
        local_data.channels = result.user_data.channels;
      }
      if (result.user_data.appSettings) {
        local_data.appSettings = result.user_data.appSettings;
        if (result.user_data.appSettings.AI_technology) {
          local_data.AI_technology = result.user_data.appSettings.AI_technology;
        }
      }

      local_data.wabaAccounts = local_data.appSettings.projectDetail
        .wabaAccounts
        ? local_data.appSettings.projectDetail.wabaAccounts
        : [];
      localStorage.setItem(
        'companyNameLogoUrl',
        JSON.stringify(result.user_data.companyNameLogoUrl)
      );
      localStorage.setItem('user', JSON.stringify(local_data));
      this.sharedService.appId = result?.user_data?.appid ?? '';
      this.homeService.sendMessage({
        type: 'menutab',
      });

      this.homeService.sendMessage({
        type: 'profile',
      });

      const redirectUrl =
        environment.dashboardPrefix + local_data.roles.menu_tab[0].path;
      this.router.navigate([redirectUrl]);
      setTimeout(() => {
        if (redirectUrl === this.router.url) {
          this.router
            .navigateByUrl('/', { skipLocationChange: true })
            .then(() => {
              this.router.navigate([redirectUrl]);
            });
        }
      }, 1000);
    });
  }
}
