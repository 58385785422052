export const stylesheetObject = [
  {
    selector: 'node',
    style: {
      'shape': 'roundrectangle',
    }
  },
  {
    selector: 'node[node_type="root"]',
    style: {
      'background-color': '#333333',
      'background-opacity': 1,
      'text-valign': 'center',
      'text-halign': 'center', // Center the label horizontally
      'background-position-x': '5%', // Set the horizontal position (e.g., center)
      'background-position-y': '50%',
      'color': '#ffffff'
    }
  },
  {
    selector: 'node[node_type="slot_question"]',
    style: {
      'background-opacity': 1,
      // 'label': 'data(node_type)',
      'text-valign': 'center',
      'text-halign': 'center',
      'background-position-x': '5%',
      'background-position-y': '50%',
      'background-color': '#777777',
      'color': '#ffffff'
    }
  },
  {
    selector: 'node[node_type="menu_question"]',
    style: {
      'background-image': 'url("assets/images/flow-chart1.png")',
      'background-opacity': 1,
      // 'background-fit': 'cover',
      // 'background-width': '100%',
      // 'background-height': '100%',
      // 'label': 'data(node_type)',
      'text-valign': 'center',
      'text-halign': 'center',
      'background-position-x': '7%',
      'background-position-y': '50%',
      'text-margin-x': 5,
      'background-color': '#777777',
      'color': '#ffffff'
    }
  },
  {
    selector: 'node[node_type="menu_item"]',
    style: {
      'background-image': 'url("assets/images/rect.png")',
      'background-opacity': 1,
      // 'label': 'data(node_type)',
      'text-valign': 'center',
      'text-halign': 'center',
      'background-position-x': '7%',
      'background-position-y': '50%',
      'text-margin-x': 5,
      'background-color': '#777777',
      'color': '#ffffff'
    }
  },
  {
    selector: 'node[node_type="conditional_response"]',
    style: {
      'background-image': 'url("assets/images/conditional1.png")',
      'background-opacity': 1,
      // 'label': 'data(node_type)',
      'text-valign': 'center',
      'text-halign': 'center',
      'background-position-x': '7%',
      'background-position-y': '50%',
      'text-margin-x': 5,
      'background-color': '#777777',
      'color': '#ffffff'
    }
  },
  {
    selector: 'node[node_type="condition"]',
    style: {
      'background-image': 'url("assets/images/condition1.png")',
      'background-opacity': 1,
      // 'label': 'data(node_type)',
      'text-valign': 'center',
      'text-halign': 'center',
      'background-position-x': '7%',
      'background-position-y': '50%',
      'text-margin-x': 5,
      'background-color': '#777777',
      'color': '#ffffff'
    }
  },
  {
    selector: 'node[node_type="end_response"]',
    style: {
      'background-opacity': 1,
      // 'label': 'data(node_type)',
      'text-valign': 'center',
      'text-halign': 'center',
      'background-position-x': '5%',
      'background-position-y': '50%',
      'background-color': '#fb7171',
      'color': '#ffffff'
    }
  },
  {
    selector: ':active',
    style: {
      'overlay-color': 'green',
      'overlay-padding': 0,
      'overlay-opacity': 0.10
    }
  },
    {
        selector: 'node:selected',
        style: {
            'background-color': '#afafaf',
        }
    },
  {
    selector: 'node[type= "bendPoint"]',
    style: {
      'width': '1.00001px',
      'height': '1.00001px'
    }
  },
  {
    selector: 'node[type = "text"]',
    style: {
      'width': '200px',
      'height': '50px',
      'content': 'data(label)',
      'label': function(node) {
        if (node.data('dummyLabel')?.length > 10) {
          return `${node.data('dummyLabel')?.substring(0, 10)}...`;
        } else {
          return `${node.data('dummyLabel') ? node.data('dummyLabel') : ''}`;
        }
      },
      'font-size': 16,
      'text-valign': 'center',
      'text-halign': 'center'
    }
  },
  {
    selector: 'edge[type = "bendPoint" ]',
    style: {
      'width': 1,
      'target-arrow-shape': 'none',
      'opacity': 1
    }
  },
  {
    selector: '.eh-handle',
    style: {
      'label': 'data(label)',
      'background-image': 'url("assets/images/arrow-left.png")',
      'overlay-padding': 0,
      'width': 20,
      'height': 20,
      'background-color': '#e9e9e9'
    }
  },
  {
    selector: '.eh-ghost',
    style: {
      'label': '',
    }
  },
  {
    selector: '.eh-hover',
    style: {
      'label': '',
    }
  },
  {
    selector: '.eh-preview',
    style: {

    }
  },
  {
    selector: 'edge',
    style: {
      'curve-style': 'taxi',
       'segment-distances': [20, -20],
       'segment-weights': [0.25, 0.75],
      'control-point-distances': [30, -30],
      'control-point-weights': [0.5, 0.5],
      'taxi-direction': 'horizontal', // or 'horizontal'
      'taxi-turn': 50,
      'taxi-turn-min-distance': 50,
      'target-arrow-shape': 'triangle',
      'target-arrow-color': '#6f6e6e',
      'line-style': 'dashed',
      'line-color': '#6f6e6e',
      'width': 2,
      'arrow-scale': 1.5,

    },
  },
    {
        selector: 'edge:selected',
        style: {
            'line-color': '#386BA7',
            'target-arrow-color': '#386BA7',
            'width': 4,
            'background-color': '#e9e9e9',
            'arrow-scale': 1.5,
            'target-arrow-shape': 'triangle',
            'mid-target-arrow-shape': 'vee'
        }
    },
  {
    selector: '.edge-highlighted',
    style: {
      'opacity': '1',
      'line-color': '#386BA7',
      'width': '4px'
    }
  },
  {
    selector: '.edge-dimmed',
    style: {
      'opacity': '0.2'
    }
  },
  {
    selector: 'node:selected',
    style: {
      'background-color': '#afafaf',
    }
  }
];
