import { AbstractControl } from '@angular/forms';
export class ValidateEmail {
  static emailValidator(control: AbstractControl) {
    const urlRegex = /^([a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$/;
    const valid = urlRegex.test(control.get('email').value);
    if (!valid) {
      control.get('email').setErrors({
        invalidEmail: true
      });
    } else {
      return null;
    }
  }
}
