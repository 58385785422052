import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class WebNotificationService {

    requestPermission() {
        //
    }

    showNotification(data) {
        //
    }

}
