import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders , HttpParams} from '@angular/common/http';
import { EnvironmentService } from 'src/app/environment.service';
import { Observable, of } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';
import { SharedsService } from 'src/app/shareds/shareds.service';
import { ExceptionHandlerService } from 'src/app/exception-handler.service';

@Injectable({
  providedIn: 'root',
})
export class UsersService {
  apiUrl: string;
  httpOptions: any;
  appId: any;
  token: any;
  argument: any;
  user_id: any;

  constructor(
    private http: HttpClient,
    private envService: EnvironmentService,
    private sharedService: SharedsService,
    private expService: ExceptionHandlerService
  ) {
    this.apiUrl = this.envService.read('apiUrl');
    this.user_id =  JSON.parse(localStorage.getItem('user')) && JSON.parse(localStorage.getItem('user')).user_id ? JSON.parse(localStorage.getItem('user')).user_id : '';
    this.setHttpHeaders();
  }

  setHttpHeaders() {
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: this.envService.read('Authorization'),
        'access-token': this.sharedService.token,
      }),
    };
  }

  getAllUsers(args): Observable<any> {
    this.httpOptions.params = null;
    args.user_id = this.user_id;
    args.timeZone = this.sharedService.timeZone;
    return this.http
      .post<any>(`${this.apiUrl}/v1/list/agentNames`, args, this.httpOptions)
      .pipe(
        tap(),
        catchError((error) => {
          this.expService.handleError(error);
          return of(error);
        })
      );
  }

  addUser(args): Observable<any> {
    args.timeZone = this.sharedService.timeZone;
    args.user_id = this.user_id;
    return this.http
      .post<any>(`${this.apiUrl}/dashboard/user/signup`, args, this.httpOptions)
      .pipe(
        tap(),
        catchError((error) => {
          this.expService.handleError(error);
          return of(error);
        })
      );
  }

  updateUser(args): Observable<any> {
    args.timeZone = this.sharedService.timeZone;
    return this.http
      .post<any>(`${this.apiUrl}/v1/update/agentRole`, args, this.httpOptions)
      .pipe(
        tap(),
        catchError((error) => {
          this.expService.handleError(error);
          return of(error);
        })
      );
  }

  deleteUser(args): Observable<any> {
    args.timeZone = this.sharedService.timeZone;
    this.httpOptions.params = null;
    return this.http
      .post<any>(`${this.apiUrl}/dashboard/user/delete`, args, this.httpOptions)
      .pipe(
        tap(),
        catchError((error) => {
          this.expService.handleError(error);
          return of(error);
        })
      );
  }

  getCreatedUserCount(args): Observable<any> {
    args.timeZone = this.sharedService.timeZone;
    this.httpOptions.params = null;
    return this.http
      .post<any>(
        `${this.apiUrl}/dashboard/user/role/count`,
        args,
        this.httpOptions
      )
      .pipe(
        tap(),
        catchError((error) => {
          this.expService.handleError(error);
          return of(error);
        })
      );
  }

  dashboardUserCount(): Observable<any> {
    this.httpOptions.params = new HttpParams()
      .set('appId', this.sharedService.appId);
      return this.http.get<any>(`${this.apiUrl}/dashboard/dashboarduserlimit`, this.httpOptions).pipe(
      tap(),
      catchError((error) => {
        this.expService.handleError(error);
        return of(error);
      })
    );
  }

  listAgentGroup(args): Observable<any> {
    this.httpOptions.params = new HttpParams()
      .set('appId', this.sharedService.appId)
      .set('search', args.search)
      .set('limit', args.limit)
      .set('skip', args.skip);
      return this.http.get<any>(`${this.apiUrl}/v1/list/agentGroup`, this.httpOptions).pipe(
      tap(),
      catchError((error) => {
        this.expService.handleError(error);
        return of(error);
      })
    );
  }
  listActiveAgentGroup(): Observable<any> {
    this.httpOptions.params = new HttpParams()
      .set('appId', this.sharedService.appId);
      return this.http.get<any>(`${this.apiUrl}/v1/list/active/agentGroup`, this.httpOptions).pipe(
      tap(),
      catchError((error) => {
        this.expService.handleError(error);
        return of(error);
      })
    );
  }
}
