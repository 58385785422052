import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { first } from 'rxjs/operators';
import { LoginService } from '../../login/login.service';
import { ValidatePassword } from './password';
import { TranslateService } from '@ngx-translate/core';
import { SharedsService } from 'src/app/shareds/shareds.service';

@Component({
  selector: 'app-setpassword',
  templateUrl: './setpassword.component.html',
  styleUrls: ['./setpassword.component.scss'],
})
export class SetpasswordComponent implements OnInit {
  newPassword: string;
  confirmPassword: string;
  setPasswordForm: UntypedFormGroup;
  resetKey: string;
  submitted = false;
  hideSetPasswordDialogBox = false;
  showLoader = false;
  userCreated = false;
  isUserValid = false;
  errorMessage = '';
  error = false;
  translatorLanguage: any;

  constructor(
    private router: Router,
    private currentRoute: ActivatedRoute,
    private toastr: ToastrService,
    private formBuilder: UntypedFormBuilder,
    private loginService: LoginService,
    private translate: TranslateService, private sharedService: SharedsService) {
      this.translatorLanguage = localStorage.getItem('Language');
      this.translate.setDefaultLang('en');
      this.translate.use(this.translatorLanguage ? this.translatorLanguage : 'en');
    }

  ngOnInit(): void {
    this.loginService.logout();
    this.resetKey = this.currentRoute.snapshot.queryParamMap.get('key');
    const args = {
      resetKey: this.resetKey,
    };

    this.loginService
      .onLoadSetPassword(args)
      .pipe(first())
      .subscribe(
        (data) => {
          if (data.status !== 200) {
            this.errorMessage = data.info;
            this.showLoader = false;
          } else {
            this.isUserValid = true;
          }
        },
        (error) => {
          this.toastr.error('', this.translate.instant('toastr.error_password'));
          this.showLoader = false;
        }
      );
    this.setPasswordForm = this.formBuilder.group(
      {
        newPassword: ['', [Validators.required, Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[!@#$%^&*])[A-Za-z\\d!@#$%^&*]{10,}')]],
        confirmPassword: ['', Validators.required],
      },
      {
        validator: ValidatePassword.MatchPassword,
      }
    );
  }

  get f() {
    return this.setPasswordForm.controls;
  }

  public onCancel() {
    this.router.navigateByUrl('/login');
  }

  onSubmit() {
    this.submitted = true;
    if (this.setPasswordForm.invalid) {
      return;
    } else {
      this.showLoader = true;
      this.hideSetPasswordDialogBox = true;
    }

    const args = {
      resetKey: this.resetKey,
      password: this.f.newPassword.value.trim(),
      conformPassword: this.f.confirmPassword.value.trim(),
    };
    this.loginService
      .setPassword(args)
      .pipe(first())
      .subscribe(
        (data) => {
          if (data.status !== 200) {
            this.sharedService.toastrFunction(data.info, 'error');
            this.showLoader = false;
          } else {
            this.toastr.success('', this.translate.instant('toastr.password_success'));
            this.userCreated = true;
            this.showLoader = false;
          }
        },
        (error) => {
          this.toastr.error('', this.translate.instant('toastr.error_password'));
          this.showLoader = false;
        }
      );
  }
}
