export class GetPaymentCountryList {
    // static getCountryList(): any {
    //   throw new Error('Method not implemented.');
    // }

    static getCountryList(): any  {
        return [
            { name: 'Bahrain', code: 'BH', currency: 'BHD' },   // Bahraini Dinar
            { name: 'Germany', code: 'DE', currency: 'EUR' },    // Euro
            { name: 'India', code: 'IN', currency: 'INR' },      // Indian Rupee
            { name: 'Indonesia', code: 'ID', currency: 'IDR' },  // Indonesian Rupiah
            { name: 'Kuwait', code: 'KW', currency: 'KWD' },     // Kuwaiti Dinar
            { name: 'Malaysia', code: 'MY', currency: 'MYR' },   // Malaysian Ringgit
            { name: 'Singapore', code: 'SG', currency: 'SGD' },  // Singapore Dollar
            { name: 'United Arab Emirates', code: 'AE', currency: 'AED' }, // UAE Dirham
            { name: 'United Kingdom', code: 'GB', currency: 'GBP' }, // British Pound Sterling
            { name: 'United States', code: 'US', currency: 'USD' }  // US Dollar
        ];
    }
}
