<div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 bColor page-top clearfix">
  <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 bg"
    style="display: block;border-radius: 0px 15px 0px 0px;padding:0px;" [ngStyle]="{'width': width ? '270px' : '70px'}">
    <div style="display:inline-flex;width:inherit;height:inherit;">
      <a class="col-xs-10 col-sm-10 col-md-10 col-lg-10 al-logo clearfix"
        style="display:block;margin:auto;padding-left:7%;">
        <img alt="" src="assets/images/dashboard/unified-ai-logo-white.avif" style="width:108px;" />
      </a>
    </div>
  </div>

  <div class="col-xs-7 col-sm-7 col-md-7 col-lg-7" style="padding-right:0px !important;display:block;margin:Auto;">
    <div class="col-xs-10 col-sm-10 col-md-10 col-lg-10" style="text-align: left;padding-left: 25px;">
      <div style="display:inline-flex;">
      </div>
    </div>
  </div>
</div>
<div style="padding: 50px 20px;">
  <app-page-header pageTitle="{{'smart_contact.new_title' | translate }}" EngTitle="Project"></app-page-header>
  <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12" style="padding:0px; ">
    <div class="page-loader" *ngIf="loadershow">
      <img class="img1" alt="" src="assets/images/dashboard/uib-loading-500x500.gif" style="width: 100px">
    </div>

    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12" style="padding-left: 0px !important;">
      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 conversation" style="padding:0px;"
        *ngFor="let x of contacts; let i = index;">
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 sep parent"
          style="padding: 10px;cursor: pointer;height: 450px;">
          <div class="col-lg-2 col-md-2 col-sm-2 col-xs-2">
            <img *ngIf="x.url!=''" [src]="x.url" style="height: 100%;width:100%;" alt="" />
          </div>

          <div class="col-lg-10 col-md-10 col-sm-10 col-xs-10" style="margin-top:5px">
            <div>
              <span style="font-size: 16px;font-weight: 600; color:#333333;word-break: break-word;">{{ x.name }}</span>
            </div>
            <div class="row "
              style="color: #828282;font-size: 15px;padding-left: 30px; padding-top: 23px; margin-left:-43px;">
              <div>
                <div class="col-md-5">
                  <span
                    style="font-weight:bold;margin-left:-18px;margin-bottom: 30px; width: 160px;text-align: center;">{{'common.channel'
                    |
                    translate }}</span>
                </div>
                <div class="col-md-5">
                  <span style="font-weight:bold;margin-bottom: 30px;">{{'smart_contact.url' | translate }}</span>
                </div>
              </div>
              <div style="height: 320px;overflow-y: scroll;width: 100%;">
                <div class="col-md-5">
                  <span *ngFor="let configuredChannel of configuredChannelsList;"
                    class="col-lg-12 col-md-12 col-sm-12 col-xs-12 btn" style="padding: 2px;cursor:context-menu;">
                    <span class="col-lg-2 col-md-2 col-sm-2 col-xs-2">
                      <em
                        *ngIf="configuredChannel.channel.toLowerCase() !== 'drift' && configuredChannel.channel.toLowerCase() !== 'microsoftteams' && configuredChannel.channel.toLowerCase() !== 'signal' && configuredChannel.channel.toLowerCase() !== 'audiocodes' && configuredChannel.channel.toLowerCase() !== 'airbnb' && configuredChannel.channel.toLowerCase() !== 'webex' && configuredChannel.channel.toLowerCase() !== 'icq' && configuredChannel.channel.toLowerCase() !== 'googleplay' && configuredChannel.channel.toLowerCase() !== 'twilio' && configuredChannel.channel.toLowerCase() !== 'lark' &&  configuredChannel.channel.toLowerCase() !== 'mattermost' &&  configuredChannel.channel.toLowerCase() !== 'googlebusiness' && configuredChannel.channel.toLowerCase() !== 'applebusinesschat' && configuredChannel.channel.toLowerCase() !== 'telerivet' && configuredChannel.channel.toLowerCase() !== 'twist' && configuredChannel.channel.toLowerCase() !== 'rcs' && configuredChannel.channel.toLowerCase() !== 'kakaotalk' && configuredChannel.channel.toLowerCase() !== 'zalo'  && configuredChannel.channel.toLowerCase() !== 'genericnlp'"
                        [style.color]="applyfontcolor(configuredChannel.channel)"
                        style="font-size:x-large !important;padding-left:0px;"
                        [ngClass]="applyFontIcon(configuredChannel.channel)" aria-hidden="true"></em>
                      <img alt="" style="height:25px;padding-right: 2vh;padding-left:0px;"
                        *ngIf="configuredChannel.channel.toLowerCase() === 'drift'" src="assets/images/drift.png" />
                      <img alt="" style="height:25px;padding-right: 2vh;padding-left:0px;"
                        *ngIf="configuredChannel.channel.toLowerCase() === 'microsoftteams'"
                        src="assets/images/microsoftTeams.png" />
                      <img alt="" style="height:25px;padding-right: 2vh;padding-left:0px;"
                        *ngIf="configuredChannel.channel.toLowerCase() === 'signal'" src="assets/images/signal.png" />
                      <img alt="" style="height:25px;padding-right: 2vh;padding-left:0px;"
                        *ngIf="configuredChannel.channel.toLowerCase() === 'audiocodes'"
                        src="assets/images/audiocodes.png" />
                      <img alt="" style="height:25px;padding-right: 2vh;padding-left:0px;"
                        *ngIf="configuredChannel.channel.toLowerCase() === 'airbnb'" src="assets/images/airbnb.png" />
                      <img alt="" style="height:25px;padding-right: 2vh;padding-left:0px;"
                        *ngIf="configuredChannel.channel.toLowerCase() === 'webex'" src="assets/images/webex.png" />
                      <img alt="" style="height:25px;padding-right: 2vh;padding-left:0px;"
                        *ngIf="configuredChannel.channel.toLowerCase() === 'icq'" src="assets/images/icq.png" />
                      <img alt="" style="height:25px;padding-right: 2vh;padding-left:0px;"
                        *ngIf="configuredChannel.channel.toLowerCase() === 'twilio'" src="assets/images/twilio.png" />
                      <img alt="" style="height:25px;padding-right: 2vh;padding-left:0px;"
                        *ngIf="configuredChannel.channel.toLowerCase() === 'rcs'" src="assets/images/rcs.jpg" />
                      <img alt="" style="height:25px;padding-right: 2vh;padding-left:0px;"
                        *ngIf="configuredChannel.channel.toLowerCase() === 'kakaotalk'"
                        src="assets/images/kakaotalk.png" />
                      <img alt="" class="col-lg-2 col-md-2 col-sm-2 col-xs-2"
                        style="height:25px;padding-right: 2vh;padding-left:0px;"
                        *ngIf="configuredChannel.channel.toLowerCase() === 'lark'" src="assets/images/lark.png" />
                      <img alt="" style="height:25px;padding-right: 2vh;padding-left:0px;"
                        *ngIf="configuredChannel.channel.toLowerCase() === 'mattermost'"
                        src="assets/images/mattermost.png" />
                      <img alt="" style="height:25px;padding-right: 2vh;padding-left:0px;"
                        *ngIf="configuredChannel.channel.toLowerCase() === 'googleplay'"
                        src="assets/images/googleplay.png" />
                      <img alt="" style="height:25px;padding-right: 2vh;padding-left:0px;"
                        *ngIf="configuredChannel.channel.toLowerCase() === 'googlebusiness'"
                        src="assets/images/googlemessages.svg" />
                      <img alt="" class="col-lg-2 col-md-2 col-sm-2 col-xs-2"
                        style="height:25px;padding-right: 2vh;padding-left:0px;"
                        *ngIf="configuredChannel.channel.toLowerCase() === 'applebusinesschat'"
                        src="assets/images/apple.png" />
                      <img alt="" style="height:25px;padding-right: 2vh;padding-left:0px;"
                        *ngIf="configuredChannel.channel.toLowerCase() === 'telerivet'"
                        src="assets/images/telerivet.png" />
                      <img alt="" style="height:25px;padding-right: 2vh;padding-left:0px;"
                        *ngIf="configuredChannel.channel.toLowerCase() === 'twist'" src="assets/images/twist.png" />
                      <img alt="" class="col-lg-2 col-md-2 col-sm-2 col-xs-2"
                        style="height:25px;padding-right: 2vh;padding-left:0px;"
                        *ngIf="configuredChannel.channel.toLowerCase() === 'zalo'" src="assets/images/zalo.png" />
                      <img alt="" style="height:25px;padding-right: 2vh;padding-left:0px;"
                        *ngIf="configuredChannel.channel.toLowerCase() === 'genericnlp'"
                        src="assets/images/genericnlp.png" />
                    </span>
                    <span class="col-md-10" style="font-size:16px;font-weight:600;padding-left:0px;text-align:start;">
                      {{applyChannelName(configuredChannel.channel)}}
                    </span>
                  </span>
                </div>
                <div class="col-md-5">
                  <span *ngFor="let configuredChannel of configuredChannelsList;"
                    class="col-lg-12 col-md-12 col-sm-12 col-xs-12 btn" style="padding:4px;cursor:context-menu;">
                    <span title="{{configuredChannel.url}}"><a href="{{configuredChannel.url}}" target="_blank"
                        rel="noopener noreferrer"
                        style="font-size:16px; position: absolute;left: 0px;width: 100%;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;text-align: left;">{{configuredChannel.url}}</a>&nbsp;</span>
                  </span>

                </div>
                <div class="col-md-2">
                  <span *ngFor="let configuredChannel of configuredChannelsList;"
                    class="col-lg-12 col-md-12 col-sm-12 col-xs-12 btn"
                    style="padding: 4px;cursor:context-menu; text-align: start; position: relative;left:6px;">
                    <span style="text-align:start;cursor:pointer;"><em style="font-size:15px;"
                        (click)="copy(configuredChannel.url)" (keydown)="copy(configuredChannel.url)" class="far fa-copy"></em>
                      &nbsp;
                      <em style="font-size:15px;" *ngIf="configuredChannel['qrCode'] !== '' " class="fa fa-qrcode"
                        (click)="displayQrTemplate(qrTemplate , configuredChannel['qrCode'])" (keydown)="displayQrTemplate(qrTemplate , configuredChannel['qrCode'])" (onKeyPress)="true" title="Scan QR Code"></em>
                    </span>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12" *ngIf="(contacts && !contacts.length)">
        <p class="no-msg" i18n="@@noRecordsfound">{{'common.no_records_found' | translate }}</p>
      </div>
    </div>
    <ng-template #qrTemplate>
      <div style="border-radius: 50px !important;width: 400px; height: 250px; position: relative;">
        <div>
          <button id="smartContactQrcodeBtn" type="button" style="color: red" class="close"
            (click)="onCancel(); ">&times;</button>
          <h4>{{'smart_contact.qrcode' | translate }}</h4>
        </div>
        <div
          style="position: absolute;top: 50%;left: 50%; -ms-transform: translate(-50%, -50%);transform: translate(-50%, -50%);">
          <img style="width: 100%; height: 100%;" alt="QR Code" [src]="value">
        </div>
      </div>
    </ng-template>
  </div>
