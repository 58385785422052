import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { EventEmitter, Injectable, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { EnvironmentService } from '../environment.service';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root',
})
export class SharedsService implements OnInit {
  NLP: boolean;
  public selectedTemplate: any = null;
  public selectedFlex: any = null;
  public appId: string;
  public token = '';
  httpOptions: any;
  apiUrl: any;
  public timeZone: any;
  public contentElementClickedFromHome = false;
  onMainEvent = new EventEmitter<string>();

  constructor(
    private http: HttpClient,
    private envService: EnvironmentService,
    private toastr: ToastrService,
      private translate: TranslateService,
  ) {
    this.timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const user = JSON.parse(localStorage.getItem('user')) ? JSON.parse(localStorage.getItem('user')) : {};
    this.appId =  user.appid ? user.appid : '';
    this.token = user.token ? user.token : '';
  }

  ngOnInit() {
    const user = JSON.parse(localStorage.getItem('user')) ? JSON.parse(localStorage.getItem('user')) : {};
    this.appId =  user.appid ? user.appid : '';
    this.token = user.token ? user.token : '';
  }

  AgentOnlineStatus(args): Observable<any> {
    this.apiUrl = this.envService.read('apiUrl');
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: this.envService.read('Authorization'),
        'access-token': this.token,
      }),
    };
    args.timeZone = this.timeZone;
    args.appId = this.appId;
    return this.http
      .post<any>(
        `${this.apiUrl}/dashboard/user/setactive`,
        args,
        this.httpOptions
      )
      .pipe();
  }

  AgentOnlineStatusAsync2() {
    const args: any = { status: 'away', timeZone: this.timeZone };
    this.apiUrl = this.envService.read('apiUrl');
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: this.envService.read('Authorization'),
        'access-token': this.token,
      }),
    };
    args.appId = this.appId;
    args.access_token = this.token;
    const data = JSON.stringify(args);

    navigator.sendBeacon(`${this.apiUrl}/dashboard/user/setactive/reload`, data);
  }
  toastrFunction(message, status) {
      this.translate.get('toastr.' + message).subscribe((res: string) => {
      if (status === 'info') {
        if (res.indexOf('toastr.') > -1) {
          this.toastr.info('', message);
        } else {
          this.toastr.info('', res);
        }
      } else if (status === 'error')  {
        if (res.indexOf('toastr.') > -1) {

          this.toastr.error('', message);

        } else {
          this.toastr.error('', res);
        }
      } else if (res.indexOf('toastr.') > -1) {
          this.toastr.success('', message);
        } else {
          this.toastr.success('', res);
        }
      });
  }
  updateLoginHistory(args): Observable<any> {
    this.apiUrl = this.envService.read('apiUrl');
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: this.envService.read('Authorization'),
        'access-token': this.token,
      }),
    };
    args.timeZone = this.timeZone;
    args.appId = this.appId;
    return this.http
      .post<any>(
        `${this.apiUrl}/dashboard/update/login`,
        args,
        this.httpOptions
      )
      .pipe();
  }
  getCompanyUrl(): Observable<any> {
    this.apiUrl = this.envService.read('apiUrl');
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: this.envService.read('Authorization'),
        'access-token': this.token,
      }),
    };
    this.httpOptions.params = new HttpParams().set(
      'appid',
      this.appId
    );
    return this.http
      .get<any>(`${this.apiUrl}/getCompanyUrl`, this.httpOptions)
      .pipe(
      );
  }
  setVersion() {
    this.apiUrl = this.envService.read('apiUrl');
    return  this.http
      .get<any>(`${this.apiUrl}`)
      .pipe(
      );
  }
}
