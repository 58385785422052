<div class="bColor page-top clearfix">
  <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 bg" style="display: block;border-radius: 0px 15px 0px 0px;margin:auto;padding:0px;" [ngStyle]="{'width': width ? '270px' : '70px'}" >
    <div style="display:inline-flex;width:inherit;height:inherit;">
      <a class="col-xs-10 col-sm-10 col-md-10 col-lg-10 al-logo clearfix" style="display:block;margin:auto;padding-left:7%;" *ngIf="width">
        <img alt="" src="assets/images/dashboard/unified-ai-logo-white.avif" width="108px" height="26px" />
      </a>
      <a class="col-xs-2 col-sm-2 col-md-2 col-lg-2 collapse-menu-link" [ngClass]="{'tac-collapase': width == false,'tac-collapase-non': width == true}" ba-sidebar-toggle-menu (click)="toogleWidth('toggle')">
        <img alt="" src="assets/images/navbar/navicon.avif"/>
      </a>
    </div>
  </div>
  
  <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12" style="padding-right:0px !important;display:block;margin:Auto;" [ngStyle]="{'width' : width ? 'calc(100% - 270px)':'calc(100% - 70px)'}">
    <div class="col-xs-10 col-sm-10 col-md-10 col-lg-10" style="text-align: left;padding-left: 25px;">
      <div style="display:inline-flex;">
        <span *ngIf="userAppNames.length > 1" class="coversation-date-span" style="display:inline-flex;padding:10px;">
          {{'navbar.smartcontact_details' | translate}}
        </span>
        <span *ngIf="userAppNames.length > 1" style="display: inline; width: 300px;">
         <div class="appDropdown" style="max-width: fit-content; min-width: 200px;">
          <ngx-select-dropdown  class="userAppStyle" style="text-align: start;" tabindex="0" [multiple]="false" [config]="config" [options]="userApps" [(ngModel)]="selectedApp" (change)="changeuserApp()">
          </ngx-select-dropdown>
          </div>
        </span>
      </div>
      <span *ngIf="userAppNames.length < 2" [ngStyle]="{'width':currentRoute === dashboardPrefix+'/liveagents' && appAgentHandover ? '65%': '70%'}" [ngClass]="{'smartContactSpan': width , 'smartContactSpanCollapsed' : !width}" style="display:inline-flex;padding:10px;"> {{'navbar.smartcontact_details' | translate}} <strong>&nbsp;{{appName}}</strong>
      </span>
      <span *ngIf="!isAppEnabled" class="coversation-date-span" style="display:inline-flex;padding:10px;color:red;">
        Application is disabled &nbsp; <span class="fa-stack fa-2x" popover = "The application has exceeded the plan limit. Please contact support." [placement]="'right'" [container]="'body'" [outsideClick]="true" triggers="mouseenter mouseleave click">
          <em class="fa fa-circle fa-stack-2x icon-background2" style="color: red;"></em>
          <em class="fa fa-info fa-stack-1x" style="color: white;font-size: 8px;"></em> 
         </span>
      </span>
    </div>
   
    <div class="col-xs-2 col-sm-2 col-md-2 col-lg-2 clearfix" style="padding-right:0px !important;">
      <app-msg-center></app-msg-center>
    </div>
  </div>
</div>
