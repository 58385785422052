import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';
import { EnvironmentService } from '../../../environment.service';
import { SharedsService } from 'src/app/shareds/shareds.service';
import { ExceptionHandlerService } from 'src/app/exception-handler.service';

@Injectable({
  providedIn: 'root'
})
export class TrialusersService {
  apiUrl: string;
  httpOptions: any;
  constructor(private http: HttpClient, private envService: EnvironmentService, private sharedService: SharedsService, private expService: ExceptionHandlerService) {
    this.apiUrl = this.envService.read('apiUrl');
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: this.envService.read('Authorization'),
        'access-token': this.sharedService.token,
      }),
    };
  }
  getTrialUser( args): Observable<any> {
    this.httpOptions.params = new HttpParams()
      .set('appId', args.appId )
      .set('search', args.search)
      .set('limit', args.limit)
      .set('skip', args.skip);
    return this.http.post<any>(`${this.apiUrl}/v1/trial/user/list`, null,
      this.httpOptions).pipe(
        tap(),
        catchError((error) => {
          this.expService.handleError(error);
          return of(error);
        })
      );
  }


  addTrialUser(args): Observable<any> {
    this.httpOptions.params = null;
    args.appId = this.sharedService.appId;
    return this.http.post<any>(`${this.apiUrl}/v1/add_user`, args,
        this.httpOptions).pipe(
            tap(),
            catchError((error) => {
              this.expService.handleError(error);
                return of(error);
            })
        );
  }

  listAllChannels(): Observable<any> {
    this.httpOptions.params = new HttpParams()
      .set('appId', this.sharedService.appId);
    return this.http.post<any>(`${this.apiUrl}/v1/list/channelname`, null, this.httpOptions).pipe(
        tap(),
        catchError((error) => {

          this.expService.handleError(error);
          return of(error);
        })
      );
  }

  deleteTrialUsers(args): Observable<any> {
    this.httpOptions.params = new HttpParams()
    .set('appId', args.appId)
    .set('userId', args.userId);
    return  this.http.post<any>(`${this.apiUrl}/v1/trial/user/delete`, null,
    this.httpOptions).pipe(
        tap(),
        catchError((error) => {
          this.expService.handleError(error);
            return of(error);
        })
    );
  }
}
